import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import electricPostImage from 'images/electric/electric_post.png';
import { IOPolygonLayerItem } from 'types/api';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
const IOElectricalNetworksDistributionPostLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_post_hta_bt = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT,
        label: 'electrical_networks_post_hta_bt',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.point,
        color: '#FF0000',
        height: 10,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksDistributionPost = (townId) => () => get(iots.array(IOElectricalNetworksDistributionPostLayerItem), `${townId}/electrical-networks/distribution-post`);
const useElectricalNetworksDistributionPostLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksDistributionPost,
        legends: getLegends(),
        markerImg: electricPostImage,
    })(filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT);
};
const ElectricalNetworksDistributionPostLayer = () => {
    useElectricalNetworksDistributionPostLayer();
    return null;
};
export default ElectricalNetworksDistributionPostLayer;
