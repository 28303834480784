import * as React from 'react';
import { IconButton, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMap, useMapCenter } from 'hooks/contexts/useMapContext';
import { useSelectedPlot } from 'hooks/contexts/useTabsContext';
import Geoloc from 'images/icons/Geoloc';
const TownPlotCenterButton = () => {
    const map = useMap();
    const selectedTown = useSelectedTown();
    const selectedPlot = useSelectedPlot();
    const mapCenter = useMapCenter();
    const { trackEvent } = useAnalytics();
    const onClick = () => {
        let coordinates;
        if (selectedPlot) {
            coordinates = new google.maps.LatLng(parseFloat(selectedPlot.lat), parseFloat(selectedPlot.lng));
        }
        else {
            coordinates = mapCenter.current;
        }
        map.setCenter(coordinates);
    };
    if (!selectedTown) {
        return;
    }
    return (<VStack backgroundColor="colorMode.background800" borderRadius="md" spacing={0} boxShadow="lg">
      <IconButton aria-label="zoom-less" variant="ghost" onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Recentrer sur la commune/parcelle',
            });
            onClick();
        }} height="32px" minWidth="32px" color="blue.500" icon={<Geoloc fill="var(--chakra-colors-gray-600)"/>}/>
    </VStack>);
};
export default TownPlotCenterButton;
