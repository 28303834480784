import * as React from 'react';
import { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_LAND_POINT } from 'analytics/useAnalytics';
import { snakeCase } from 'lodash';
import DeleteButton from 'components/buttons/DeleteButton';
import LandPointModal from 'components/modals/LandPointModal';
import PlotModal from 'components/modals/landPointModal/PlotModal';
import Table from 'components/table/Table';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useLandPoint from 'hooks/useLandPoint';
import { formatDate } from 'utils/formatDate';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text as="span" align="center">
        {t(`tabs.land_point.columns.${column}`)}
      </Text>)}
  </Translation>);
const EXPORTED_FIELDS = ['participant', 'nbProject', 'closeDate'];
const formatExportHeader = (t) => (header) => t(`tabs.land_point.columns.${snakeCase(header)}`);
const formatExportValue = (value) => {
    return value;
};
const LandPointsPanel = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const userData = useUserData();
    const { landPoints, isLoading, deleteLandPoint, isDeleting } = useLandPoint({
        toLoad: 'list',
    });
    const onClick = (onOpen) => {
        onOpen();
        trackEvent({
            category: ANALYTICS_LAND_POINT,
            action: 'Ouvrir un point foncier',
        });
    };
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'managerName',
                        header: () => getColumnHeader('manager'),
                        cell: (info) => {
                            return (<LandPointModal landPoint={info.row.original} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={() => onClick(onOpen)}>
                      {info.getValue()}
                    </Link>)}/>);
                        },
                    },
                    {
                        accessorKey: 'participants',
                        header: () => getColumnHeader('participant'),
                        cell: (info) => info.getValue().join(', '),
                    },
                    {
                        accessorKey: 'nbProject',
                        header: () => getColumnHeader('nb_project'),
                        cell: (info) => info.getValue(),
                    },
                    {
                        accessorKey: 'createdAt',
                        header: () => getColumnHeader('created_at'),
                        cell: (info) => formatDate(info.getValue(), 'short'),
                    },
                    {
                        accessorKey: 'closedAt',
                        header: () => getColumnHeader('close_date'),
                        cell: (info) => {
                            return (<LandPointModal landPoint={info.row.original} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={() => onClick(onOpen)}>
                      {info.getValue()
                                        ? formatDate(info.getValue(), 'short')
                                        : t('tabs.land_point.open')}
                    </Link>)}/>);
                        },
                    },
                    {
                        accessorKey: 'id',
                        header: () => '',
                        cell: (info) => {
                            const { t } = useTranslation();
                            if (info.row.original.manager === userData.email) {
                                return (<DeleteButton mode="icon" confirmText={t('tabs.land_point.delete_confirm')} handleDelete={() => deleteLandPoint({ landPointId: info.row.original.id })} disabled={isDeleting} size="sm"/>);
                            }
                            else {
                                return;
                            }
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, []);
    return (<Stack marginX={5} height="100%">
      <Table id={`landPoint`} columns={columns} data={landPoints || []} isLoading={isLoading} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} exportTitle={t('tabs.land_point.title')}/>
      <PlotModal />
    </Stack>);
};
export default LandPointsPanel;
