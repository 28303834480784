import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { VECTOR_TYPES } from '../../types/types';
const IOElectricalNetworksHTAAerialLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_medium_hta_aerial = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_AERIAL,
        label: 'electrical_networks_medium_hta_aerial',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#0000FF',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksHTAAerial = (townId) => () => get(iots.array(IOElectricalNetworksHTAAerialLayerItem), `${townId}/electrical-networks/HTA-aerial`);
const useElectricalNetworksHTAAerialLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksHTAAerial,
        legends: getLegends(),
    })(filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_AERIAL);
};
const ElectricalNetworksHTAAerialLayer = () => {
    useElectricalNetworksHTAAerialLayer();
    return null;
};
export default ElectricalNetworksHTAAerialLayer;
