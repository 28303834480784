import * as React from 'react';
import { Box, Switch } from '@chakra-ui/react';
import { useTogglePlotSourcePostsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import { getRandomHexColor } from 'utils/randomColor';
export const PlotSourcePostsHighlightsSwitch = ({ plotSourcePostInfo, ...props }) => {
    const { arePlotSourcePostsHighlighted, togglePlotSourcePostsHighlights } = useTogglePlotSourcePostsHighlights();
    const color = getRandomHexColor();
    const onChange = () => {
        togglePlotSourcePostsHighlights([plotSourcePostInfo], color);
    };
    const highlighted = arePlotSourcePostsHighlighted([plotSourcePostInfo]);
    return (<DefaultPlotSwitch onChange={onChange} highlighted={highlighted} {...props}/>);
};
const DefaultPlotSwitch = ({ onChange, highlighted, ...props }) => {
    const isChecked = highlighted && !props.isDisabled;
    const switchProps = { ...props, onChange };
    return (<Box>
      <Switch size="sm" isChecked={isChecked} {...switchProps}/>
    </Box>);
};
