import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import LegendImage from 'images/legends/wind_potential_160.png';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrl(map, 'POTENTIEL.VENT.160M', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.wind_potential_160 = {
        layerKey: filtersKey.WIND_POTENTIAL_160,
        label: '',
        needsTranslate: false,
        image: LegendImage,
        height: '90px',
    };
    return legends;
};
const useWindPotential160Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_POTENTIAL_160],
        getLegends,
        opacity: 0.6,
    })(filtersKey.WIND_POTENTIAL_160);
};
const WindPotential160Layer = () => {
    useWindPotential160Layer();
    return null;
};
export default WindPotential160Layer;
