import * as React from 'react';
import { memo, useEffect } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Stack, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import { t } from 'i18next';
import { snakeCase } from 'lodash';
import TeamPlotModal from 'components/modals/TeamPlotModal';
import Table from 'components/table/Table';
import { tableFiltersKey } from 'context/FiltersContext';
import { CRM_TABS } from 'context/TabsContext';
import { useUpdateTableFilter } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import useTeamPlots from 'hooks/crm/useTeamPlots';
import { getPlotCodeFromStatus, getPlotContactStatusFromCode, getPlotStatusFromCode, getProjectStatusFromCode, } from 'utils/constants';
import { registerTableFilters } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
import { inDateRange } from 'utils/search';
import useAnalytics, { ANALYTICS_MAP_BUTTONS, } from '../../../analytics/useAnalytics';
import { TABS_VIEW_FULL, TABS_VIEW_FULL_VERTICAL, } from '../../../context/LayoutContext';
import { useChangeLayout, useTabsView, } from '../../../hooks/contexts/useLayoutContext';
const getColumnHeader = (column) => (<Translation>
    {(t) => <Text align="center">{t(`tabs.team_plot.columns.${column}`)}</Text>}
  </Translation>);
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'userEmail',
                header: () => getColumnHeader('user_email'),
                accessorFn: (row) => {
                    if (!row.userEmail)
                        return t('table.empty_column');
                    return row.userEmail;
                },
                cell: (info) => info.row.original.userEmail,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'cadastreId',
                header: () => getColumnHeader('cadastre_id'),
                accessorFn: (row) => {
                    if (!row.cadastreId)
                        return t('table.empty_column');
                    return row.cadastreId;
                },
                cell: (info) => {
                    const disclosureProps = useDisclosure();
                    return (<TeamPlotModal plot={{
                            plotId: info.row.original.plotId,
                            cadastreId: info.row.original.cadastreId,
                            address: info.row.original.address,
                            email: info.row.original.userEmail,
                            lat: info.row.original.plotLat,
                            lng: info.row.original.plotLng,
                            townshipId: info.row.original.townId,
                        }} {...disclosureProps}/>);
                },
                filterFn: 'arrIncludesSome',
            },
            {
                id: 'plotStatus',
                accessorFn: (row) => {
                    if (!row.plotStatus)
                        return t('table.empty_column');
                    return getPlotStatusFromCode(row.plotStatus);
                },
                header: () => getColumnHeader('plot_status'),
                cell: ({ row }) => getPlotStatusFromCode(row.original.plotStatus) ?? '',
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'address',
                header: () => getColumnHeader('address'),
                accessorFn: (row) => {
                    if (!row.address)
                        return t('table.empty_column');
                    return row.address;
                },
                cell: (info) => info.row.original.address,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'zipcode',
                header: () => getColumnHeader('zipcode'),
                accessorFn: (row) => {
                    if (!row.zipcode)
                        return t('table.empty_column');
                    return row.zipcode;
                },
                cell: (info) => info.getValue(),
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'townLabel',
                header: () => getColumnHeader('town'),
                accessorFn: (row) => {
                    if (!row.townLabel)
                        return t('table.empty_column');
                    return row.townLabel;
                },
                cell: (info) => info.getValue(),
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'surface',
                header: () => getColumnHeader('surface'),
                cell: (info) => (<Text align="right" minWidth="100px" as="span">
            {info.row.original.surface} m²
          </Text>),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'zone',
                header: () => getColumnHeader('zone'),
                accessorFn: (row) => {
                    if (!row.zone)
                        return t('table.empty_column');
                    return row.zone;
                },
                cell: (info) => info.row.original.zone,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'historyDate',
                header: () => getColumnHeader('history_date'),
                cell: (info) => formatDate(info.row.original.historyDate, 'extended'),
                enableGlobalFilter: false,
                filterFn: inDateRange,
            },
            {
                accessorKey: 'historyType',
                header: () => getColumnHeader('history_type'),
                accessorFn: (row) => {
                    if (!row.historyType)
                        return t('table.empty_column');
                    return row.historyType;
                },
                cell: (info) => info.row.original.historyType,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'projects',
                header: () => getColumnHeader('projects_label'),
                accessorFn: (row) => {
                    if (!row.projects.length)
                        return t('table.empty_column');
                    return row.projects[0].label;
                },
                cell: (info) => {
                    const projects = info.row.original.projects;
                    return (projects && (<VStack spacing={2}>
                {Object.values(projects).map((project) => (<Box key={project.id}>
                    {project.label} ({getProjectStatusFromCode(project.status)})
                  </Box>))}
              </VStack>));
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'contact.lastname',
                header: () => getColumnHeader('contact_name'),
                accessorFn: (row) => {
                    if (!row.contact?.lastname)
                        return t('table.empty_column');
                    return row.contact?.lastname;
                },
                cell: (info) => info.row.original.contact?.lastname ?? '',
                filterFn: 'arrIncludesSome',
            },
            {
                id: 'plotContactStatus',
                accessorFn: (row) => {
                    if (!row.plotStatus)
                        return t('table.empty_column');
                    return getPlotContactStatusFromCode(row.plotStatus);
                },
                header: () => getColumnHeader('plot_contact_status'),
                cell: ({ row }) => getPlotContactStatusFromCode(row.original.plotStatus),
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'comment',
                header: () => getColumnHeader('comment'),
                accessorFn: (row) => {
                    if (!row.comment)
                        return t('table.empty_column');
                    return row.comment;
                },
                cell: (info) => info.row.original.comment,
                filterFn: 'arrIncludesSome',
            },
        ],
    },
];
const EXPORTED_FIELDS = [
    'userEmail',
    'plotId',
    'address',
    'zipcode',
    'town',
    'surface',
    'zone',
    'historyDate',
    'historyType',
    'projectLabel',
    'projectStatus',
    'plotStatus',
    'contactName',
    'plotContactStatus',
    'comment',
    'plotLat',
    'plotLng',
];
const formatExportHeader = (t) => (header) => t(`tabs.team_plot.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'plotStatus') {
        return getPlotStatusFromCode(value);
    }
    if (field === 'projectStatus') {
        return getProjectStatusFromCode(value);
    }
    if (field === 'plotContactStatus') {
        return getPlotContactStatusFromCode(value);
    }
    if (field === 'historyDate') {
        return formatDate(value, 'extended');
    }
    return value;
};
const initialSort = [
    {
        id: 'historyDate',
        desc: true,
    },
];
const TeamPlotsPanel = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const toggleLayerTab = useToggleLayerTab();
    const { teamPlots, isLoading, error } = useTeamPlots();
    const updateTableFilter = useUpdateTableFilter();
    const tabsView = useTabsView();
    const { deployLayout, reduceLayout } = useChangeLayout();
    useEffect(() => {
        if (error) {
            toggleLayerTab({ tabId: CRM_TABS.TEAM_PLOTS, closeTab: true });
        }
    }, [error]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    useEffect(() => {
        registerTableFilters({
            keysToRegister: [
                {
                    name: tableFiltersKey.PLOT_USER_EMAIL,
                    formatValue: (value) => value,
                },
                {
                    name: tableFiltersKey.PLOT_STATUS,
                    formatValue: (value) => value.map((status) => parseInt(getPlotCodeFromStatus(status))),
                },
            ],
            columnFilters,
            updateTableFilter,
        });
    }, [columnFilters]);
    // This page has restricted access so we don't want to show anything
    // if the query returns an error
    if (error) {
        return null;
    }
    return (<Stack paddingX={5} height="100%">
      <Table id="table-team-plots" initialSort={initialSort} columns={COLUMNS} headerTitle={tabsView === TABS_VIEW_FULL_VERTICAL ||
            tabsView === TABS_VIEW_FULL ? (<Button variant="primary" size="sm" onClick={() => {
                reduceLayout();
                trackEvent({
                    category: ANALYTICS_MAP_BUTTONS,
                    action: 'Les projets de mon équipe voir carte',
                });
            }}>
              {t('global.see_map')}
            </Button>) : (<Button variant="primary" size="sm" onClick={() => {
                deployLayout();
                trackEvent({
                    category: ANALYTICS_MAP_BUTTONS,
                    action: 'Les projets de mon équipe voir tableau',
                });
            }}>
              {t('global.table')}
            </Button>)} data={teamPlots || []} isLoading={isLoading} emptyMessage={t(`tabs.team_plot.no_result`)} exportTitle={t('tabs.team_plot.title')} analyticsAction="Les parcelles de mon équipe" exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} customColumnFilters={columnFilters} setCustomColumnFilters={setColumnFilters}/>
    </Stack>);
};
export default memo(TeamPlotsPanel);
