import * as iots from 'io-ts';
import { unknown } from 'io-ts';
import { IOPlotFeasibilityWithCadastre } from 'hooks/plot/usePlotFeasibility';
import { IOCoordinate } from 'types/types';
import { AGENDA_ACTIVITY_TYPE_EVENT, AGENDA_ACTIVITY_TYPE_LAND_POINT, AGENDA_ACTIVITY_TYPE_REMINDER, AGENDA_ACTIVITY_TYPE_TASK, AGENDA_ACTIVITY_TYPE_UNDEFINED, } from 'utils/constants';
export const IOTownMapAvailable = iots.type({
    thr: iots.number,
    '1950-1965': iots.number,
    '2000-2005': iots.number,
    '2006-2010': iots.number,
    '2011-2015': iots.number,
    2016: iots.number,
    2017: iots.number,
    2018: iots.number,
    2019: iots.number,
    2020: iots.number,
});
export const IOTown = iots.type({
    name: iots.string,
    pluiName: iots.union([iots.null, iots.string]),
    EPCIName: iots.string,
    id: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    zipCode: iots.string,
    inseeCode: iots.string,
    department: iots.string,
    cityHall: iots.type({
        name: iots.string,
        website: iots.union([iots.null, iots.string]),
        phone: iots.union([iots.null, iots.string]),
        email: iots.union([iots.null, iots.string]),
    }),
    docs: iots.type({
        carence: iots.union([iots.null, iots.string]),
        charte: iots.union([iots.null, iots.string]),
        floodingPPR: iots.union([iots.null, iots.string]),
        groundMovementsPPR: iots.union([iots.null, iots.string]),
        naturalPPR: iots.union([iots.null, iots.string]),
        notice: iots.union([iots.null, iots.string]),
        oap: iots.union([iots.null, iots.string]),
        ort: iots.union([iots.null, iots.string]),
        plu: iots.union([iots.null, iots.string]),
        pluFutur: iots.union([iots.null, iots.string]),
        technologicalPPR: iots.union([iots.null, iots.string]),
        realEstateTax: iots.union([iots.null, iots.string]),
    }),
    infoType: iots.union([iots.null, iots.string]),
    infoDate: iots.union([iots.null, iots.string]),
    infoDateFusion: iots.union([iots.null, iots.string]),
    lastDate: iots.union([iots.null, iots.string]),
    lastCheckDate: iots.union([iots.null, iots.string]),
    scot: iots.number,
    pinel: iots.string,
    hasPLU: iots.boolean,
    showPLU: iots.boolean,
    hasFeasibility: iots.boolean,
    hasRegulationAnalysis: iots.boolean,
    dgfipEmails: iots.union([iots.array(iots.string), iots.null]),
    pinelAccession: iots.number,
    rent: iots.type({
        house: iots.number,
        apartment: iots.number,
        T1T2: iots.number,
        T3AndMore: iots.number,
    }),
    additionalPLU: iots.union([
        iots.null,
        iots.type({
            infoType: iots.union([iots.null, iots.string]),
            infoDate: iots.union([iots.null, iots.string]),
            lastDate: iots.union([iots.null, iots.string]),
        }),
    ]),
    additionalFeasibility: iots.boolean,
    mapAvailable: IOTownMapAvailable,
    swkt: iots.union([iots.null, iots.string]),
});
export const IOLegend = iots.type({
    id: iots.string,
    layerKey: iots.string,
    label: iots.string,
    needsTranslate: iots.boolean,
    image: iots.string,
});
// ------------ LAYER ITEMS ------------
const IOBaseLayerItem = iots.intersection([
    iots.type({
        id: iots.string,
    }),
    iots.partial({
        parentLayerKey: iots.union([iots.string, iots.null]),
        layerKey: iots.union([iots.string, iots.null]),
        legend: iots.union([iots.string, iots.null, iots.undefined]),
        legendData: iots.union([IOLegend, iots.null, iots.undefined]),
        legendOrder: iots.union([iots.number, iots.undefined]),
        legendNeedsTranslate: iots.union([iots.boolean, iots.undefined]),
        legendOpacity: iots.union([iots.number, iots.undefined]),
        legendWithCheckbox: iots.union([iots.boolean, iots.undefined]),
        infoTitle: iots.union([iots.string, iots.null, iots.undefined]),
        infoLine1: iots.union([iots.string, iots.null, iots.undefined]),
        infoLine2: iots.union([iots.string, iots.null, iots.undefined]),
        lat: IOCoordinate,
        lng: IOCoordinate,
        label: iots.union([iots.string, iots.null, iots.undefined]),
        labelClass: iots.union([iots.string, iots.null, iots.undefined]),
        projectStatus: iots.union([iots.number, iots.undefined]),
    }),
], 'IOBaseLayerItem');
const IOTeamProjectPlots = iots.type({
    id: iots.number,
    color: iots.string,
    swkt: iots.string,
});
export const IOMarkerLayerItem = iots.intersection([
    IOBaseLayerItem,
    iots.type({
        lat: IOCoordinate,
        lng: IOCoordinate,
    }),
    iots.partial({
        type: iots.string,
        withPolygon: iots.union([iots.boolean, iots.undefined]),
        title: iots.union([iots.string, iots.undefined]),
        userEmail: iots.union([iots.string, iots.undefined]),
        townId: iots.union([iots.string, iots.undefined]),
        plots: iots.array(IOTeamProjectPlots),
    }),
], 'IOMarkerLayerItem');
export const IOPolygonLayerItem = iots.intersection([
    IOBaseLayerItem,
    iots.type({
        swkt: iots.string,
    }),
    iots.partial({
        type: iots.string,
        color: iots.string,
        opacity: iots.union([iots.number, iots.null]),
        borderColor: iots.string,
        borderWidth: iots.number,
        borderOpacity: iots.number,
        radius: iots.union([iots.number, iots.null]),
        markerKey: iots.string,
        polygonBounds: unknown,
        userEmail: iots.union([iots.string, iots.undefined]),
    }),
], 'IOPolygonLayerItem');
export const IOLayerItem = iots.union([IOMarkerLayerItem, IOPolygonLayerItem]);
// ------------ LAYERS TREE ------------
const IOBaseFilter = iots.intersection([
    iots.type({
        id: iots.string,
    }),
    iots.partial({
        title: iots.string,
        prefix: iots.string,
        needsTranslate: iots.boolean,
        count: iots.number,
        subtitle: iots.string,
        radioName: iots.string,
        subAreas: iots.array(iots.string),
        subAreasAdditional: iots.array(iots.string),
    }),
], 'IOBaseFilter');
export const IOTranslatedFilterItem = IOBaseFilter;
export const IOFilterItemWithLegend = iots.intersection([
    IOBaseFilter,
    iots.type({
        color: iots.string,
    }),
    iots.partial({
        opacity: iots.number,
        borderColor: iots.string,
    }),
], 'IOFilterItemWithLegend');
// ------------ PLOTS ------------
export const IOPlotProjectContact = iots.type({
    id: iots.string,
    townId: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    plotNumber: iots.string,
    surface: iots.number,
});
export const IOFilterPath = iots.array(iots.array(iots.string));
export const IOPlotLayer = iots.type({
    name: iots.string,
    affected: iots.string,
    category: iots.string,
    filterPaths: iots.union([iots.null, IOFilterPath]),
    documents: iots.union([iots.null, iots.undefined, iots.array(iots.string)]),
});
export const IOPlotJson = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        id: iots.string,
        cadastreId: iots.string,
        section: iots.string,
        plotNumber: iots.string,
        number: iots.string,
        surface: iots.number,
        height: iots.number,
        address: iots.array(iots.string),
        town: iots.string,
        zone: iots.string,
        subZone: iots.string,
        streetNumber: iots.string,
        streetRep: iots.string,
        street: iots.string,
        streetLine: iots.number,
        type: iots.string,
        swkt: iots.string,
        lat: IOCoordinate,
        lng: IOCoordinate,
        townId: iots.string,
        floorArea: iots.number,
    }),
    iots.partial({
        layers: iots.array(IOPlotLayer),
    }),
], 'IOPlotJson');
// ------------ ACTIVITY ------------
const IOAgendaActivityType = iots.union([
    iots.literal(AGENDA_ACTIVITY_TYPE_UNDEFINED),
    iots.literal(AGENDA_ACTIVITY_TYPE_EVENT),
    iots.literal(AGENDA_ACTIVITY_TYPE_TASK),
    iots.literal(AGENDA_ACTIVITY_TYPE_REMINDER),
    iots.literal(AGENDA_ACTIVITY_TYPE_LAND_POINT),
], 'IOActivityAgendaType');
// ------------ PROJECTS ------------
export const IOProjectNote = iots.intersection([
    iots.type({
        id: iots.string,
        note: iots.string,
        createdAt: iots.string,
        updatedAt: iots.string,
    }),
    iots.partial({
        plotNumber: iots.string,
        plotId: iots.string,
    }),
], 'IOProjectNote');
export const IOProjectContact = iots.type({
    id: iots.string,
    firstname: iots.string,
    lastname: iots.string,
    status: iots.number,
    mobile: iots.string,
    landline: iots.string,
    comment: iots.string,
    plots: iots.array(IOPlotProjectContact),
});
export const IOProjectPlot = iots.intersection([
    iots.type({
        addedAt: iots.string,
        address: iots.union([iots.string, iots.null]),
        plotNumber: iots.string,
        groundSurface: iots.number,
        id: iots.string,
        lat: IOCoordinate,
        lng: IOCoordinate,
        level: iots.number,
        projectPlotId: iots.string,
        status: iots.number,
        surface: iots.number,
        swkt: iots.string,
        townId: iots.string,
        section: iots.string,
        zone: iots.string,
        number: iots.string,
        contactId: iots.union([iots.string, iots.null]),
    }),
    IOLayerItem,
], 'IOProjectPlot');
export const IOProject = iots.intersection([
    iots.type({
        id: iots.string,
        label: iots.string,
        status: iots.number,
    }),
    iots.partial({
        user: iots.string,
        createdAt: iots.string,
        updatedAt: iots.string,
        rightToBuild: iots.union([iots.string, iots.number]),
        lots: iots.union([iots.string, iots.number]),
        turnover: iots.union([iots.string, iots.number]),
        landCharge: iots.union([iots.string, iots.number]),
        copromotion: iots.boolean,
        copromotionLots: iots.union([iots.string, iots.number]),
        copromotionTurnover: iots.union([iots.string, iots.number]),
        copromotionFloorArea: iots.union([iots.string, iots.number]),
        destination: iots.array(iots.number),
        estate: iots.number,
        groundSurface: iots.number,
        plots: iots.array(IOProjectPlot),
        username: iots.string,
    }),
], 'IOProject');
const IOProjectAddedPlot = iots.type({
    action: iots.literal('add'),
    plot: IOProjectPlot,
});
const IOProjectRemovedPlot = iots.type({
    action: iots.literal('remove'),
    plotId: iots.string,
});
export const IOProjectManagePlot = iots.union([
    IOProjectAddedPlot,
    IOProjectRemovedPlot,
]);
export const IOProjectHistory = iots.type({
    date: iots.string,
    value: iots.string,
    plotNumber: iots.union([iots.string, iots.null]),
    lng: iots.union([IOCoordinate, iots.null]),
    lat: iots.union([IOCoordinate, iots.null]),
    townId: iots.union([iots.string, iots.null]),
    plotId: iots.union([iots.string, iots.null]),
});
export const IOTeamProjectNotes = iots.type({
    id: iots.string,
    note: iots.string,
    createdAt: iots.string,
    updatedAt: iots.string,
    plotNumber: iots.number,
    plotId: iots.string,
});
export const IOTeamProjectPolygonLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        userEmail: iots.string,
        projectStatus: iots.number,
        townId: iots.string,
        projectId: iots.string,
    }),
], 'IOTeamProjectPolygonLayerItem');
export const IOProjectCreation = iots.type({
    id: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    label: iots.string,
});
// ------------ CONTACTS ------------
const IOContactPlot = iots.type({
    id: iots.string,
    address: iots.string,
    townId: iots.string,
    contactId: iots.string,
    cadastreId: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    zone: iots.union([iots.string, iots.null]),
    surface: iots.number,
    status: iots.number,
});
const IOContactNote = iots.type({
    id: iots.string,
    note: iots.union([iots.null, iots.string]),
    createdAt: iots.string,
    updatedAt: iots.string,
});
export const IOContactActivity = iots.type({
    id: iots.string,
    contactId: iots.union([iots.string, iots.null]),
    plotId: iots.union([iots.string, iots.null]),
    projectId: iots.union([iots.string, iots.null]),
    date: iots.string,
    agendaType: IOAgendaActivityType,
    comment: iots.union([iots.string, iots.null]),
    status: iots.number,
    recurrence: iots.number,
    title: iots.union([iots.string, iots.null]),
    location: iots.union([iots.string, iots.null]),
});
const IOContactProject = iots.type({
    contactId: iots.string,
    id: iots.string,
    town: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    label: iots.string,
    status: iots.number,
    townId: iots.string,
});
export const IOContactGlobal = iots.intersection([
    iots.type({
        id: iots.string,
        firstname: iots.string,
        lastname: iots.string,
        address: iots.union([iots.string, iots.null]),
        addressBP: iots.union([iots.string, iots.null]),
        addressComplement: iots.union([iots.string, iots.null]),
        postalCode: iots.union([iots.string, iots.null]),
        town: iots.union([iots.string, iots.null]),
        country: iots.union([iots.string, iots.null]),
        titleStr: iots.string,
        consent: iots.number,
        company: iots.union([iots.string, iots.null]),
        siren: iots.union([iots.string, iots.null]),
        mobile: iots.union([iots.string, iots.null]),
        landline: iots.union([iots.string, iots.null]),
        type: iots.number,
    }),
    iots.partial({
        title: iots.number, // for form
        townId: iots.union([iots.string, iots.null]),
        createdAt: iots.string,
        updatedAt: iots.union([iots.string, iots.null]),
        lastSentAt: iots.union([iots.string, iots.null]),
        primaryEmail: iots.union([iots.string, iots.null]),
        addressesPlots: iots.union([iots.string, iots.null]),
        vendor: iots.number,
        npr: iots.number,
        npai: iots.boolean,
        status: iots.number,
        hasFutureActivity: iots.boolean,
        plots: iots.array(IOContactPlot),
        notes: iots.array(IOContactNote),
        projects: iots.array(IOContactProject),
        ownerId: iots.union([iots.string, iots.null]),
        plotIds: iots.string,
        comment: iots.union([iots.string, iots.null]),
    }),
], 'IOContact');
export const IOContact = iots.intersection([
    IOContactGlobal,
    iots.type({
        id: iots.string,
    }),
], 'IOContactEdit');
export const IOContactCreate = IOContactGlobal;
export const IOPlotContact = iots.intersection([
    IOContactGlobal,
    iots.type({
        id: iots.string,
        plotId: iots.string,
        plotContactId: iots.union([iots.string, iots.null]),
        plotContactStatus: iots.number,
        lots: iots.number,
        groundSurface: iots.number,
        comment: iots.string,
    }),
], 'IOPlotContact');
export const CONTACT_SECTION = 'contact';
export const CONTACT_PLOTS = 'plots';
export const CONTACT_PROJECTS = 'projects';
export const CONTACT_NOTES = 'notes';
export const CONTACT_ACTIVITIES = 'activities';
export const CONTACT_COURIERS = 'couriers';
const IOSiteConfigurationContactTab = iots.union([
    iots.literal(CONTACT_SECTION),
    iots.literal(CONTACT_PLOTS),
    iots.literal(CONTACT_PROJECTS),
    iots.literal(CONTACT_NOTES),
    iots.literal(CONTACT_ACTIVITIES),
    iots.literal(CONTACT_COURIERS),
], 'IOSiteConfigurationContactTab');
// ------------ ACTIVITY ------------
export const IOEditActivity = iots.intersection([
    iots.type({
        contactId: iots.string,
        date: iots.string,
        agendaType: IOAgendaActivityType,
        status: iots.number,
        contact: iots.union([IOContact, iots.null]),
    }),
    iots.partial({
        id: iots.string,
        plotId: iots.string,
        projectId: iots.string,
        dateEnd: iots.string,
        title: iots.string,
        location: iots.string,
        comment: iots.string,
        recurrence: iots.number,
        groupActivityId: iots.string,
        updateOnlyOne: iots.number,
    }),
], 'IONewActivity');
export const IOActivity = iots.type({
    id: iots.string,
    cadastreId: iots.string,
    plotId: iots.string,
    contactId: iots.string,
    projectId: iots.string,
    date: iots.string,
    dateEnd: iots.union([iots.string, iots.null]),
    agendaType: IOAgendaActivityType,
    comment: iots.string,
    status: iots.number,
    townId: iots.string,
    townLabel: iots.string,
    townLng: IOCoordinate,
    townLat: IOCoordinate,
    plotLng: IOCoordinate,
    plotLat: IOCoordinate,
    delay: iots.number,
    recurrence: iots.number,
    title: iots.union([iots.string, iots.null]),
    location: iots.union([iots.string, iots.null]),
    contact: iots.union([IOContact, iots.null]),
});
export const IOActivityCreate = iots.type({
    plotId: iots.union([iots.string, iots.null]),
    contactId: iots.union([iots.string, iots.null]),
    cadastreId: iots.union([iots.string, iots.null]),
    projectId: iots.union([iots.string, iots.null]),
});
export const IOActivityCreation = iots.type({
    id: iots.string,
});
export const IOActivityDelete = iots.type({
    id: iots.string,
    projectId: iots.string,
    contactId: iots.string,
    plotId: iots.string,
});
export const IOPlotActivity = iots.type({
    id: iots.string,
    contactId: iots.union([iots.string, iots.null]),
    plotId: iots.union([iots.string, iots.null]),
    projectId: iots.union([iots.string, iots.null]),
    cadastreId: iots.string,
    date: iots.string,
    dateEnd: iots.union([iots.string, iots.null]),
    agendaType: IOAgendaActivityType,
    comment: iots.string,
    status: iots.boolean,
    recurrence: iots.number,
    title: iots.union([iots.string, iots.null]),
    location: iots.union([iots.string, iots.null]),
    contact: iots.union([IOContact, iots.null]),
});
// ------------ COURIER ---------------
export const IOStatsCourier = iots.type({
    nbCredit: iots.number,
    nbCourierRemaining: iots.number,
    dateLimit: iots.union([iots.string, iots.null]),
});
export const IOCourier = iots.type({
    id: iots.string,
    createDate: iots.string,
    civility: iots.string,
    firstname: iots.string,
    lastname: iots.string,
    company: iots.string,
    address: iots.string,
    addressBP: iots.string,
    addressComplement: iots.string,
    postalCode: iots.string,
    town: iots.string,
    country: iots.string,
    npai: iots.union([iots.number, iots.null]),
    senderId: iots.string,
    civilitySender: iots.string,
    firstnameSender: iots.string,
    lastnameSender: iots.string,
    companySender: iots.string,
    addressSender: iots.string,
    addressBPSender: iots.string,
    addressComplementSender: iots.string,
    postalCodeSender: iots.string,
    townSender: iots.string,
    documentUrl: iots.string,
    documentName: iots.string,
    documentPath: iots.string,
    executionDate: iots.union([iots.string, iots.null]),
    cancellationDate: iots.union([iots.string, iots.null]),
    mailevaIdentifier: iots.string,
    mailevaStatus: iots.string,
    mailevaSubmissionDate: iots.union([iots.string, iots.null]),
    mailevaProcessedDate: iots.union([iots.string, iots.null]),
    mailevaOptionColor: iots.number,
    mailevaOptionTreatUndeliveredMail: iots.number,
    mailevaOptionNbPage: iots.number,
    credits: iots.number,
    contactId: iots.string,
});
export const IOCourierForm = iots.type({
    id: iots.string,
    contactId: iots.string,
    senderId: iots.string,
    civility: iots.string,
    firstname: iots.string,
    lastname: iots.string,
    company: iots.string,
    address: iots.string,
    addressBP: iots.string,
    addressComplement: iots.string,
    postalCode: iots.string,
    town: iots.string,
    country: iots.string,
});
export const IOCourierSender = iots.type({
    id: iots.string,
    civility: iots.string,
    firstname: iots.string,
    lastname: iots.string,
    company: iots.string,
    address: iots.string,
    addressBP: iots.string,
    addressComplement: iots.string,
    postalCode: iots.string,
    town: iots.string,
    country: iots.string,
});
export const IOCourierCreation = iots.type({
    id: iots.string,
});
export const IOSenderCreation = iots.type({
    id: iots.string,
});
// ------------ SECONDARY PLOTS ------------
export const IOSecondaryPlotJson = iots.intersection([IOLayerItem, IOPlotFeasibilityWithCadastre], 'IOSecondaryPlotJson');
export const IOSecondaryPlot = iots.intersection([
    IOSecondaryPlotJson,
    iots.type({
        lat: IOCoordinate,
        lng: IOCoordinate,
        townId: iots.string,
    }),
], 'IOSecondaryPlot');
// ------------ PLOTS POLYGON ------------
export const IOPlotPolygon = IOPolygonLayerItem;
// ------------ DOCUMENT ------------
export const IODocument = iots.type({
    id: iots.string,
    url: iots.string,
    name: iots.string,
    date: iots.string,
    typeId: iots.number,
    plotId: iots.number,
    projectId: iots.number,
    plotNumber: iots.string,
});
// ------------ USER ------------
export const IOUserPlotsNews = iots.type({
    cadastreId: iots.string,
    townId: iots.string,
    township: iots.string,
    postalCode: iots.string,
    plotId: iots.string,
    lng: IOCoordinate,
    lat: IOCoordinate,
    oldSZ: iots.string,
    newSZ: iots.string,
    oldSDP: iots.number,
    newSDP: iots.number,
    oldEMP: iots.number,
    newEMP: iots.number,
    oldMIX: iots.number,
    newMIX: iots.number,
    newNIV: iots.number,
    oldNIV: iots.number,
    oldPinel: iots.string,
    newPinel: iots.string,
    plotNumber: iots.string,
    modifiedAt: iots.string,
    contactIdsDeath: iots.array(iots.string),
});
// ------------ PUNCHLINE ------------
export const IOUserPunchline = iots.type({
    description: iots.string,
});
// ------------ PLU ------------
export const IOPLUInfo = iots.type({
    name: iots.string,
    area: iots.string,
    subarea: iots.string,
    url: iots.union([iots.string, iots.null]),
});
export const IOPLUAdditionalLayerItem = iots.intersection([
    iots.type({
        zone: iots.string,
        subZone: iots.string,
        description: iots.string,
        url: iots.string,
    }),
    IOLayerItem,
], 'IOPLUAdditionalLayerItem');
// ------------ PLU GPU ------------
export const IOPLUGPUInfo = iots.type({
    id: iots.string,
    name: iots.string,
    area: iots.string,
    subarea: iots.string,
    url: iots.union([iots.string, iots.null]),
});
// ------------ SITE CONFIGURATION ------------
// Keep in sync with application/src/Constant/UserSiteConfigurationConstant.php
const IOSiteConfigurationFeature = iots.union([
    iots.literal('old_site_link'),
    iots.literal('header_search_plot'),
    iots.literal('contact_import'),
    iots.literal('publipostage'),
    iots.literal('plu_adjacent'),
]);
const IOSiteConfigurationMapControl = iots.union([
    iots.literal('display_satign'),
    iots.literal('display_oblique'),
    iots.literal('cadastre'),
    iots.literal('map'),
    iots.literal('measure'),
    iots.literal('display_satign_road'),
]);
const IOSiteConfigurationDashboardItem = iots.union([
    iots.literal('agenda'),
    iots.literal('teamPlots'),
    iots.literal('teamStats'),
    iots.literal('user_plots'),
    iots.literal('myProject'),
    iots.literal('user_projects'),
    iots.literal('myContact'),
    iots.literal('accessRightUser'),
    iots.literal('historic'),
    iots.literal('myStats'),
    iots.literal('courier'),
    iots.literal('landPoint'),
]);
const IOSiteConfigurationSearchField = iots.union([
    iots.literal('buildingFloorArea'),
    iots.literal('buildingPurpose'),
    iots.literal('constructionYear'),
    iots.literal('coOwnerLots'),
    iots.literal('currentBuildingFootprint'),
    iots.literal('eaveHeight'),
    iots.literal('excludeFloodProneAreas'),
    iots.literal('excludePotentiallyPollutedSites'),
    iots.literal('numberOfLevels'),
    iots.literal('oneOrMoreOwners'),
    iots.literal('ownerType'),
    iots.literal('possibleElevations'),
    iots.literal('roofingMaterial'),
    iots.literal('savedFilters'),
    iots.literal('smallFootprints'),
    iots.literal('undevelopedPlots'),
    iots.literal('wallMaterial'),
    iots.literal('zones'),
    iots.literal('officeConstruction'),
    iots.literal('storeConstruction'),
    iots.literal('warehouseConstruction'),
    iots.literal('removeStreets'),
    iots.literal('roadLinear'),
    iots.literal('reducedVATZone'),
    iots.literal('plotArea'),
    iots.literal('buildable'),
    iots.literal('allowedFloorArea'),
    iots.literal('permittedBuildingFootprint'),
    iots.literal('authorizedLevel'),
    iots.literal('titleDeeds'),
    iots.literal('ownerMainActivity'),
    iots.literal('ownerName'),
    iots.literal('ownerSiren'),
    iots.literal('socialMix'),
    iots.literal('farming'),
    iots.literal('lightweightConstruction'),
    iots.literal('excludePotentiallyPollutedSites'), // obsolete
]);
const IOSiteConfigurationMenuItem = iots.union([
    iots.literal('dashboard'),
    iots.literal('urbanism'),
    iots.literal('news'),
    iots.literal('search'),
]);
export const PLOT_PLOT_TAB = 'plot';
export const SOURCE_POST_PLOT_TAB = 'source_post';
export const PLOT_RULES_PLOT_TAB = 'plot_rules';
export const FEASIBILITY_PLOT_TAB = 'feasibility';
export const FEASIBILITY_ADDITIONAL_PLOT_TAB = 'feasibility_additional';
export const FEASIBILITY_LIGHT_PLOT_TAB = 'feasibility_light';
export const PLU_REGULATION_PLOT_TAB = 'plu_regulation';
export const PLU_ADDITIONAL_REGULATION_PLOT_TAB = 'plu_additional_regulation'; // PLU en vigueur
export const OWNER_PLOT_TAB = 'owner';
export const OCCUPANT_PLOT_TAB = 'occupant';
export const BUILDING_PLOT_TAB = 'building';
export const SALES_PLOT_TAB = 'sales';
export const NEIGHBORHOOD_PLOT_TAB = 'neighborhood';
export const MY_CRM_PLOT_TAB = 'my_crm';
export const DPE_PLOT_TAB = 'dpe';
const IOSiteConfigurationPlotTab = iots.union([
    iots.literal(PLOT_PLOT_TAB),
    iots.literal(SOURCE_POST_PLOT_TAB),
    iots.literal(PLOT_RULES_PLOT_TAB),
    iots.literal(FEASIBILITY_PLOT_TAB),
    iots.literal(FEASIBILITY_LIGHT_PLOT_TAB),
    iots.literal(FEASIBILITY_ADDITIONAL_PLOT_TAB),
    iots.literal(PLU_REGULATION_PLOT_TAB),
    iots.literal(PLU_ADDITIONAL_REGULATION_PLOT_TAB),
    iots.literal(OWNER_PLOT_TAB),
    iots.literal(OCCUPANT_PLOT_TAB),
    iots.literal(BUILDING_PLOT_TAB),
    iots.literal(SALES_PLOT_TAB),
    iots.literal(MY_CRM_PLOT_TAB),
    iots.literal(NEIGHBORHOOD_PLOT_TAB),
    iots.literal(DPE_PLOT_TAB),
], 'IOSiteConfigurationPlotTab');
export const PLOT_INFO_FARMING = 'farming';
const IOSiteConfigurationPlotInfo = iots.union([
    iots.literal('information'),
    iots.literal('status'),
    iots.literal('zone'),
    iots.literal('google'),
    iots.literal(PLOT_INFO_FARMING),
]);
export const PROJECT_SECTION = 'project';
export const PROJECT_FEASIBILITY = 'feasibility';
export const PROJECT_RULES = 'rules';
export const PROJECT_FOLLOW = 'follow';
export const PROJECT_PLOTS = 'plots';
export const PROJECT_DOCUMENTS = 'documents';
export const PROJECT_PHOTOS = 'photos';
export const PROJECT_FINANCE = 'finance';
const IOSiteConfigurationProjectTab = iots.union([
    iots.literal(PROJECT_SECTION),
    iots.literal(PROJECT_FEASIBILITY),
    iots.literal(PROJECT_RULES),
    iots.literal(PROJECT_FOLLOW),
    iots.literal(PROJECT_PLOTS),
    iots.literal(PROJECT_DOCUMENTS),
    iots.literal(PROJECT_PHOTOS),
    iots.literal(PROJECT_FINANCE),
], 'IOSiteConfigurationProjectTab');
// Keep in sync with application/src/Constant/UserSiteConfigurationConstant.php
export const IOSiteConfiguration = iots.partial({
    features: iots.array(IOSiteConfigurationFeature),
    map_controls: iots.array(IOSiteConfigurationMapControl),
    dashboard: iots.array(IOSiteConfigurationDashboardItem),
    foncier: iots.array(IOSiteConfigurationSearchField),
    menu: iots.array(IOSiteConfigurationMenuItem),
    plot_tabs: iots.array(IOSiteConfigurationPlotTab),
    project_tabs: iots.array(IOSiteConfigurationProjectTab),
    plot: iots.array(IOSiteConfigurationPlotInfo),
    layers_tree: iots.record(iots.string, iots.unknown),
});
// ------------ OTHER ------------
export const IOUserSite = iots.type({
    legacyId: iots.string,
    title: iots.union([iots.string, iots.null]),
    url: iots.string,
    favicon: iots.union([iots.string, iots.null]),
    icon: iots.union([iots.string, iots.null]),
    logo: iots.string,
    ownerSubscription: iots.boolean,
    ownerPublicity: iots.boolean,
    configuration: iots.union([IOSiteConfiguration, iots.null]),
});
export const IODocUrbaLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        name: iots.string,
    }),
], 'IODocUrbaLayerItem');
export const IOEmpty = iots.array(iots.undefined);
// ------------ PUBLIPOSTAGE ------------
export const IOTemplatePublipostage = iots.type({
    id: iots.string,
    pathAndFilename: iots.string,
    title: iots.string,
});
const IOContactCourier = iots.type({
    firstname: iots.string,
    lastname: iots.string,
});
export const IOCourierCreationPublipostage = iots.type({
    contactsNoFr: iots.array(IOContactCourier),
    incompleteContacts: iots.array(IOContactCourier),
});
// ------------ SITE ------------
export const IOSite = iots.type({
    id: iots.string,
    logo: iots.string,
});
export const IOSiteCarrousel = iots.type({
    id: iots.string,
    image: iots.string,
    title: iots.string,
    subtitle: iots.string,
});
// -------- LANDPOINT ----------
export const IOLandPoint = iots.type({
    id: iots.string,
    manager: iots.string,
    managerName: iots.string,
    participants: iots.array(iots.string),
    nbProject: iots.number,
    closedAt: iots.union([iots.string, iots.null]),
    createdAt: iots.union([iots.string, iots.null]),
});
export const IOLandPointCreation = iots.type({
    participant: iots.string,
    projectId: iots.string,
});
export const IOLandPointProject = iots.type({
    id: iots.string,
    landPointId: iots.string,
    userName: iots.string,
    userEmail: iots.string,
    label: iots.string,
    status: iots.number,
    townId: iots.string,
    town: iots.string,
    nbPlot: iots.number,
    surface: iots.number,
    nbContact: iots.number,
    rightToBuild: iots.number,
    lots: iots.number,
    turnover: iots.number,
    lat: IOCoordinate,
    lng: IOCoordinate,
});
export const IOLandPointActivity = iots.type({
    id: iots.string,
    comment: iots.string,
    status: iots.number,
    createdAt: iots.string,
});
export const IOLandPointActivities = iots.record(iots.string, iots.array(IOLandPointActivity));
export const IOLandPointReport = iots.type({
    id: iots.string,
    landPointId: iots.string,
    projectId: iots.string,
    comment: iots.string,
    date: iots.string,
    encloseDate: iots.union([iots.string, iots.null]),
});
export const IOLandPointReports = iots.record(iots.string, IOLandPointReport);
export const IOLandPointPrevReports = iots.record(iots.string, iots.array(IOLandPointReport));
