import { t } from 'i18next';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import lineDataImage from 'images/electric/electric_line_data.png';
import pylonImage from 'images/electric/electric_pylon.png';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { dataToSwkt } from 'utils/geometry';
const getLayerData = (townId, townScot, selectedTown, filterKey) => () => {
    const distance = 5; // km
    const url = `https://data.enedis.fr/api/explore/v2.1/catalog/datasets/${filterKey}/exports/json?` +
        'select=geo_shape&where=within_distance%28geo_shape%2C%20GEOM%27POINT%28' +
        `${selectedTown.lng}%20${selectedTown.lat}%29%27%2C%20${distance}km%29` +
        '&limit=-1&timezone=UTC&use_labels=false&epsg=4326';
    return get(null, url, {}, true, {
        withCredentials: false,
        headers: { Authorization: null },
    });
};
const formatFetch = (filterKeys, data) => {
    if (!data) {
        return data;
    }
    const filterName = filterKeys[0];
    return data.map((info) => {
        const geo = {
            id: info.geo_shape.geometry.coordinates.join('-'),
            layerKey: filterName,
            type: '',
            swkt: '',
            legend: getLegend(filterName),
            color: '',
            markerImg: '',
            borderColor: '',
            borderWidth: 0,
            borderOpacity: 0,
        };
        if (info.geo_shape.geometry.type.toUpperCase() === 'MULTILINESTRING' ||
            info.geo_shape.geometry.type.toUpperCase() === 'LINESTRING') {
            geo.type = VECTOR_TYPES.polyline;
            geo.borderColor = getColor(filterName);
            geo.borderWidth = 5;
            geo.borderOpacity = 1;
        }
        else if (info.geo_shape.geometry.type.toUpperCase() === 'POINT') {
            geo.type = VECTOR_TYPES.marker;
            geo.markerImg = getMarkerImage(filterName);
        }
        geo.swkt = dataToSwkt(info.geo_shape.geometry.type.toUpperCase(), info.geo_shape.geometry.coordinates);
        return geo;
    });
};
const getColor = (layer) => {
    switch (layer) {
        case filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT:
            return '#FF0000';
        case filtersKey.ELECTRICAL_NETWORKS_DATA_LINE_AND_POST:
            return '#FF0000';
    }
    return '#FF0000';
};
const getLegend = (layer) => {
    switch (layer) {
        case filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT:
            return t('legends.electrical_networks_pylon_hta_bt');
        case filtersKey.ELECTRICAL_NETWORKS_DATA_LINE_AND_POST:
            return t('legends.electrical_networks_data_line_and_post');
    }
    return '';
};
const getMarkerImage = (layer) => {
    switch (layer) {
        case filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT:
            return pylonImage;
        case filtersKey.ELECTRICAL_NETWORKS_DATA_LINE_AND_POST:
            return lineDataImage;
    }
    return '';
};
const getMarkerImg = (item) => {
    return item.markerImg;
};
const useElectricalNetworksByTypeLayers = (filterKey) => {
    createLayersHook({
        fetch: getLayerData,
        formatFetch: formatFetch,
        extraParams: [filterKey],
        markerImg: getMarkerImg,
    })(filterKey);
};
const ElectricalNetworksByTypeLayers = ({ filterKey }) => {
    useElectricalNetworksByTypeLayers(filterKey);
    return null;
};
export default ElectricalNetworksByTypeLayers;
