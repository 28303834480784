import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MASQUER } from 'analytics/useAnalytics';
import FilterCategoryDocument from 'components/FilterCategoryDocument';
import Table from 'components/table/Table';
import PlotLayerSwitch from 'components/tabs/plots/PlotSections/info/PlotLayerSwitch';
import ZoomMoinsIcon from 'images/icons/ZoomMoins';
import ZoomPlusIcon from 'images/icons/ZoomPlus';
import { FROM_PLOT_RULES } from 'utils/constants';
export const NO_IMPACT_RULE = 'Non concernée';
const REGEX_NO_IMPACT_RULE = new RegExp(`.?${NO_IMPACT_RULE}.?`, 'gi');
const formatPlotLayers = (layers = []) => {
    // Some layers have a nested format with multiple filterPaths, we need to decompose them to display them correctly
    const cleanedLayers = [];
    layers.map((layer) => {
        if (layer?.filterPaths?.length > 1 && layer?.affected?.includes(';')) {
            const names = layer?.affected.split(';');
            layer?.filterPaths?.map((filterPaths, index) => {
                cleanedLayers.push({
                    ...layer,
                    name: layer?.name,
                    affected: names[index] ?? layer?.affected,
                    filterPaths: [filterPaths],
                });
            });
        }
        else {
            cleanedLayers.push(layer);
        }
    });
    return cleanedLayers;
};
const PlotLayers = ({ layers, isLoading, landPointId, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const [showNoImpactRules, setShowNoImpactRules] = useState(false);
    const [plotLayers, setPlotLayers] = useState([]);
    const [sortedCategories, setSortedCategories] = useState([]);
    useEffect(() => {
        let plotLayers = formatPlotLayers(layers);
        plotLayers = showNoImpactRules
            ? plotLayers
            : plotLayers.filter((layer) => {
                return !layer.affected?.match(REGEX_NO_IMPACT_RULE);
            });
        setPlotLayers(plotLayers);
        const categories = (plotLayers || []).map((plot) => plot?.category);
        setSortedCategories(categories.filter((item, pos) => {
            return categories.indexOf(item) === pos;
        }));
    }, [JSON.stringify(layers), showNoImpactRules]);
    const columns = useMemo(() => [
        {
            id: 'main-columns',
            columns: [
                {
                    accessorKey: 'name',
                    header: () => {
                        return (<>
                  <Button variant="link" marginTop="2px" leftIcon={<Box as="span" color="red.500" backgroundColor="red.100" borderRadius="md" width="20px" display="flex" justifyContent="center">
                        {showNoImpactRules ? (<ZoomMoinsIcon width="10" fill="currentColor"/>) : (<ZoomPlusIcon width="10" fill="currentColor"/>)}
                      </Box>} onClick={() => {
                                setShowNoImpactRules(!showNoImpactRules);
                                trackEvent({
                                    category: ANALYTICS_MASQUER,
                                    action: 'Parcelle tout afficher',
                                });
                            }}>
                    <Text color="red.500" as="span" textStyle="small">
                      {showNoImpactRules
                                ? t('tabs.plots.section.plot.hide_no_impact_rules')
                                : t('tabs.plots.section.plot.show_no_impact_rules')}
                    </Text>
                  </Button>
                </>);
                    },
                    accessorFn: (row) => {
                        if (!row.name)
                            return t('table.empty_column');
                        return row.name;
                    },
                    cell: ({ row }) => {
                        return (<Box>
                  <Text>{row.original.name}</Text>
                  {row.original.documents && (<FilterCategoryDocument documentKeys={row.original.documents} from={FROM_PLOT_RULES}/>)}
                </Box>);
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'affected',
                    header: () => {
                        return t('tabs.plots.section.plot.affected');
                    },
                    accessorFn: (row) => {
                        if (!row.affected)
                            return t('table.empty_column');
                        return row.affected;
                    },
                    cell: ({ row }) => {
                        return row.original.affected;
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'filterPaths',
                    header: () => {
                        return t('tabs.plots.section.plot.layerKey');
                    },
                    cell: ({ row }) => {
                        return (row.original.filterPaths && (<PlotLayerSwitch plotLayer={row.original}/>));
                    },
                    filterFn: 'arrIncludesSome',
                    enableColumnFilter: false,
                    enableSorting: false,
                },
                {
                    accessorKey: 'category',
                    header: () => {
                        return t('tabs.plots.section.plot.category');
                    },
                    accessorFn: (row) => {
                        if (!row.category)
                            return t('table.empty_column');
                        return row.category;
                    },
                    cell: ({ row }) => {
                        return row.original.category;
                    },
                    filterFn: 'arrIncludesSome',
                },
            ],
        },
    ], [showNoImpactRules, setShowNoImpactRules]);
    return (<Table initialPagination={{ pageIndex: 0, pageSize: 1000 }} categories={sortedCategories} variant="simple" id="plot_layers_tables" columns={columns} withExport={false} autoResetPageIndex={false} analyticsAction="Onglet parcelle" data={plotLayers} isLoading={isLoading} analytics={{
            getColumnName: (id) => t(`tabs.plots.section.plot.${id}`),
            tableName: t('tabs.plots.section.plot.title'),
        }} initialColumnVisibility={{
            filterPaths: !landPointId,
        }}/>);
};
export default PlotLayers;
