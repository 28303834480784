import * as React from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Center, HStack, Spinner, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_PROJECT_TAB, } from 'analytics/useAnalytics';
import ExportButton, { EXPORT_TYPE_PROJECT, ExportItemButton, } from 'components/buttons/ExportButton';
import { exportDataToXLSX } from 'components/export/excelUtils';
import { exportDataToPdf } from 'components/export/pdfUtils';
import { getSingleSectionSheets } from 'components/export/project/projectExportUtils';
import { getFeasibilityDefaultValues } from 'components/tabs/plots/PlotSections/feasibility/calculator';
import { getFeasibilityWithCadastre } from 'components/tabs/plots/PlotSections/feasibility/PlotFeasibility';
import PanelNavigation from 'components/tabs/TabsNavigation/PanelNavigation';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { PROJECT_TAB_TYPE } from 'context/TabsContext';
import { useUserData } from 'hooks/contexts/useAuthContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useResetPlotSelectionMode, useSelectedTown, useSetClickedCoord, } from 'hooks/contexts/useLocationContext';
import { useSecondaryPlotCoord, useSetFeasibilityParentId, useSetSecondaryPlotCoord, } from 'hooks/contexts/useSecondaryPlotsContext';
import { useDeleteTab, useSelectedTab } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import usePlotsFeasibility from 'hooks/plot/usePlotsFeasibility';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PROJECT_SECTION, } from 'types/api';
import { FEASIBILITY_PLOT_TAB } from 'types/api';
import { filtersKey } from 'utils/filters';
import { PROJECT_TOOLBAR_HEIGHT, SECTIONS } from 'utils/project';
const ProjectPanel = ({ projectId, projectTownId, landPointId }) => {
    const scrollRef = useRef();
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { trackEvent } = useAnalytics();
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const { hasProjectTab } = useSiteConfiguration();
    const selectedTab = useSelectedTab();
    const deleteTab = useDeleteTab();
    const onClose = () => deleteTab({ type: PROJECT_TAB_TYPE, id: projectId });
    const [selectedSection, setSelectedSection] = useState(PROJECT_SECTION);
    const { project, isLoading, projectPlots, isLoadingPlots, deleteProject, togglePlotInProject, removePlotFromProject, removePlotFromProjectLoading, projectPlotDeletionVariables, addNoteToProject, removeNoteFromProject, updateNoteFromProject, deletePlotNoteFromProject, } = useProject(projectTownId, projectId, landPointId);
    const userData = useUserData();
    const isUserOwner = userData.email === project?.user;
    const mainPlot = projectPlots ? projectPlots[0] : [];
    const plotIds = projectPlots?.map((plot) => plot.id);
    const { data: feasibilityPlots, isInitialLoading } = usePlotsFeasibility(projectTownId, plotIds);
    const feasibilityMainPlot = useMemo(() => feasibilityPlots
        ? getFeasibilityWithCadastre(feasibilityPlots, mainPlot)
        : null, [feasibilityPlots]);
    const [secondaryPlots, setSecondaryPlots] = useState({});
    const methods = useForm();
    useEffect(() => {
        if (!feasibilityPlots) {
            return;
        }
        const secondaryPlots = {};
        const plots = [...feasibilityPlots];
        plots.forEach((feasibilityPlot) => {
            const plot = projectPlots?.find((plot) => plot.id == feasibilityPlot.id);
            secondaryPlots[feasibilityPlot.id] = {
                ...feasibilityPlot,
                lat: plot.lat,
                lng: plot.lng,
                zone: plot.zone,
                section: plot.section,
                number: plot.number,
            };
        });
        setSecondaryPlots(secondaryPlots);
        methods.reset(getFeasibilityDefaultValues({
            feasibility: feasibilityMainPlot,
            feasibilityPlots: secondaryPlots,
        }));
    }, [feasibilityMainPlot, feasibilityPlots]);
    const getFeasibilityData = {
        activeSection: FEASIBILITY_PLOT_TAB,
        feasibility: {
            ...feasibilityMainPlot,
            formData: methods.getValues(),
        },
        feasibilityPlots: secondaryPlots,
        t,
    };
    const exportAnalyticsEventFeasibilityProject = {
        category: ANALYTICS_CATEGORY_DOWNLOAD,
        action: 'Faisabilité Projet',
    };
    const clickedCoord = useSetClickedCoord();
    const secondaryPlotCoord = useSecondaryPlotCoord();
    const setSecondaryPlotCoord = useSetSecondaryPlotCoord();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const toggleFilter = useToggleFilter();
    const removePlot = (plot, removeProject) => {
        removePlotFromProject({
            projectId,
            projectPlotId: plot.projectPlotId.toString(),
            removeProject,
        }, {
            onSuccess: removeProject ? onClose : undefined,
        });
    };
    useEffect(() => {
        setFeasibilityParentId(project?.id);
        return () => {
            resetPlotSelectionMode();
            toggleFilter(filtersKey.HIGHLIGHTED_PLOTS);
        };
    }, []);
    useEffect(() => {
        if (selectedTab !== projectId) {
            resetPlotSelectionMode();
            toggleFilter(filtersKey.HIGHLIGHTED_PLOTS);
        }
        else {
            toggleFilter(filtersKey.PROJECT_PLOTS, true);
        }
    }, [selectedTab]);
    useEffect(() => {
        if (!!secondaryPlotCoord &&
            secondaryPlotCoord.mode === PLOT_SELECTION_PROJECT &&
            (selectedTab === projectId || landPointId)) {
            togglePlotInProject({
                townshipId: selectedTown?.id.toString(),
                projectId,
                plotId: secondaryPlotCoord.id,
                lat: secondaryPlotCoord.coord.lat().toString(),
                lng: secondaryPlotCoord.coord.lng().toString(),
            }, {
                onSuccess: () => {
                    clickedCoord(null);
                    setSecondaryPlotCoord(null);
                },
                onError: () => {
                    setSecondaryPlotCoord(null);
                    clickedCoord(null);
                },
            });
        }
    }, [secondaryPlotCoord]);
    useEffect(() => {
        if (!isLoading && !project) {
            onClose();
        }
    }, [isLoading]);
    if (isLoading || !project) {
        return (<Center height="100%" width="100%">
        <Spinner size="lg"/>
      </Center>);
    }
    const handleChangeSection = (section) => {
        setSelectedSection(section);
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };
    const Component = SECTIONS[selectedSection] || undefined;
    const allowedSections = Object.keys(SECTIONS).filter((section) => {
        return hasProjectTab(section);
    });
    const exportButton = (<ExportButton analyticsEvent={{
            category: ANALYTICS_CATEGORY_DOWNLOAD,
            action: 'Télécharger projet',
        }} exportType={EXPORT_TYPE_PROJECT} exportParams={{ projectId: project.id, landPointId: landPointId }}>
      {hasProjectTab(FEASIBILITY_PLOT_TAB) && (<>
          <ExportItemButton onExport={() => {
                const fileName = project.label + '-faisabilité';
                exportDataToXLSX(fileName, getSingleSectionSheets(getFeasibilityData));
                trackEvent(exportAnalyticsEventFeasibilityProject);
            }}>
            {t('table.export_feasibility_xlsx')}
          </ExportItemButton>
          <ExportItemButton onExport={() => {
                const fileName = project.label + '-faisabilité';
                exportDataToPdf(fileName, getSingleSectionSheets(getFeasibilityData));
                trackEvent(exportAnalyticsEventFeasibilityProject);
            }}>
            {t('table.export_feasibility_pdf')}
          </ExportItemButton>
        </>)}
    </ExportButton>);
    return (<Box height="100%" width="100%">
      <VStack height="100%" spacing={0}>
        <HStack width="100%" bg="white" display="flex" justify="space-between">
          <PanelNavigation isLoading={false} handleChangeSection={handleChangeSection} allowedSections={allowedSections} panelTitle="project" trackEventCategory={ANALYTICS_PROJECT_TAB} section={selectedSection}/>
          <Box>{exportButton}</Box>
        </HStack>
        <Box ref={scrollRef} height={`calc(100% - ${PROJECT_TOOLBAR_HEIGHT})`} overflowY="auto" width="100%" id="project-panel-scrollable" opacity={1} padding={5}>
          <>
            {Component && (<Component isUserOwner={isUserOwner} projectId={projectId} projectTownId={projectTownId} project={project} landPointId={landPointId} isLoading={isLoading} projectPlots={projectPlots} isLoadingPlots={isLoadingPlots} removePlot={removePlot} removePlotFromProjectLoading={removePlotFromProjectLoading} currentDeletedProjectPlot={projectPlotDeletionVariables?.projectPlotId} addNoteToProject={addNoteToProject} deletePlotNoteFromProject={deletePlotNoteFromProject} removeNoteFromProject={removeNoteFromProject} updateNoteFromProject={updateNoteFromProject} feasibilityPlots={feasibilityPlots} feasibilityMainPlot={feasibilityMainPlot} secondaryPlots={secondaryPlots} isInitialLoading={isInitialLoading} methods={methods} deleteProject={deleteProject} onClose={onClose}/>)}
          </>
        </Box>
      </VStack>
    </Box>);
};
export default memo(ProjectPanel);
