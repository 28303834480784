import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, VStack } from '@chakra-ui/react';
import DashboardButton from 'components/buttons/DashboardButton';
import { menuItems } from 'components/sidebar/utils';
import { CRM_TABS } from 'context/TabsContext';
import { useChangeLayout } from 'hooks/contexts/useLayoutContext';
import { useToggleMenu } from 'hooks/contexts/useMenuContext';
import { useToggleCRMTab, useToggleLayerTab, } from 'hooks/contexts/useTabsContext';
import PlotIcon from 'images/icons/Plot';
import ProjectIcon from 'images/icons/Project';
import StatisticIcon from 'images/icons/Statistic';
import UserIcon from 'images/icons/User';
import DashboardTitle from './DashboardTitle';
const Team = ({ hasDashboardItem }) => {
    const { t } = useTranslation();
    const toggleLayerTab = useToggleLayerTab();
    const toggleCRMTab = useToggleCRMTab();
    const toggleMenu = useToggleMenu();
    const { deployLayout } = useChangeLayout();
    if (!hasDashboardItem('teamPlots') && !hasDashboardItem('teamStats')) {
        return null;
    }
    return (<Box data-cy="dashboard_team">
      <DashboardTitle label={t(`tabs.dashboard.title_team`)} icon={UserIcon}/>
      <VStack>
        {hasDashboardItem('teamPlots') && (<DashboardButton onClick={() => {
                deployLayout();
                toggleMenu(menuItems?.DASHBOARD, false);
                toggleLayerTab({ tabId: CRM_TABS.TEAM_PLOTS });
            }} label={t('tabs.dashboard.team_plots')} icon={PlotIcon} id="dashboard_button_team_plots"/>)}
        {hasDashboardItem('teamPlots') && (<DashboardButton onClick={() => {
                deployLayout();
                toggleMenu(menuItems?.DASHBOARD, false);
                toggleLayerTab({ tabId: CRM_TABS.TEAM_PROJECTS });
            }} label={t('tabs.dashboard.team_projects')} icon={ProjectIcon} id="dashboard_button_team_projects"/>)}

        {hasDashboardItem('teamStats') && (<>
            <DashboardButton onClick={() => {
                deployLayout();
                toggleCRMTab({ tabId: CRM_TABS.TEAM_STATISTICS });
            }} label={t('tabs.dashboard.statistic_team')} icon={StatisticIcon} id="dashboard_button_statistic_team"/>
            <DashboardButton onClick={() => {
                deployLayout();
                toggleCRMTab({ tabId: CRM_TABS.TEAM_STATISTICS_CRM });
            }} label={t('tabs.dashboard.statistic_team_crm')} icon={StatisticIcon} id="dashboard_button_statistic_team_crm"/>
          </>)}
      </VStack>
    </Box>);
};
export default Team;
