import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import KelfoncierWhite from 'images/logo_kelFoncier_white.png';
import { IOSite } from 'types/api';
export function getSiteLogoWhite(site) {
    return site.logo ? require('../images/sites/' + site.logo) : KelfoncierWhite;
}
export function getSiteLogo(site) {
    return site.logo ? require('../images/sites/' + site.logo) : LogoKelFoncier;
}
export function getSiteName(site) {
    if (!site) {
        return 'Kel Foncier';
    }
    switch (site.id) {
        case '128': // foncier facile
        case '135': // immo foncier facile
        case '136': // pro foncier facile
            return 'Foncier Facile';
        default:
            return 'Kel Foncier';
    }
}
const QUERY_KEY = ['site'];
const fetchSite = () => () => get(IOSite, `public/site`);
const useSite = () => {
    const { data: site, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchSite(),
    });
    return {
        site,
        isLoading,
    };
};
export default useSite;
