import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import LegendImage from 'images/legends/wind_potential_100.png';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlTileUrl(map, 'Energie', 'cartes_des_vents_100m', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.wind_potential_100 = {
        layerKey: filtersKey.WIND_POTENTIAL_100,
        label: '',
        needsTranslate: false,
        image: LegendImage,
        height: '90px',
    };
    return legends;
};
const useWindPotential100Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_POTENTIAL_100],
        getLegends,
        opacity: 0.6,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.WIND_POTENTIAL_100);
};
const WindPotential100Layer = () => {
    useWindPotential100Layer();
    return null;
};
export default WindPotential100Layer;
