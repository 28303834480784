import { useEffect, useMemo, useState } from 'react';
import useAnalytics, { ANALYTICS_ONGLET } from 'analytics/useAnalytics';
import { PLOT_SELECTION_INITIAL } from 'context/LocationContext';
import { usePlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { usePlots, useSectionHash, useSelectedTab, useSetSectionHashToUrl, useSetSelectedBuilding, useTogglePlot, } from 'hooks/contexts/useTabsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import usePlotsBuildings from 'hooks/plot/usePlotsBuildings';
import usePlotsPotentialBuildings from 'hooks/plot/usePlotsPotentialBuildings';
import { BUILDING_PLOT_TAB, FEASIBILITY_PLOT_TAB } from 'types/api';
import { filtersKey } from 'utils/filters';
import { getCustomLayerInfo } from './Layers';
const BUILDING_MODE = 'buildings';
const BUILDING_FEASIBILITY_MODE = 'buildings_feasibility';
const useSelectedPlotsBuildingsLayer = () => {
    const { trackEvent } = useAnalytics();
    const setSelectedBuilding = useSetSelectedBuilding();
    const setSectionHashToUrl = useSetSectionHashToUrl();
    const togglePlot = useTogglePlot();
    const sectionHash = useSectionHash();
    const selectedTab = useSelectedTab();
    const [mode, setMode] = useState(null);
    const plotSelectionMode = usePlotSelectionMode();
    const plots = usePlots();
    const { data: buildings } = usePlotsBuildings(plots);
    const { data: potentialBuildings } = usePlotsPotentialBuildings(plots);
    const hasPotentialBuildings = useMemo(() => {
        return (Object.keys(potentialBuildings).length > 0 &&
            !!Object.values(potentialBuildings).find((item) => item.plotId === selectedTab));
    }, [potentialBuildings, selectedTab]);
    const legends = {
        selected_plots_buildings: {
            layerKey: filtersKey.SELECTED_PLOTS_BUILDINGS,
            label: 'selected_plots_buildings',
            needsTranslate: true,
            color: buildings?.[Object.keys(buildings)[0]]?.color,
        },
    };
    useEffect(() => {
        if (hasPotentialBuildings && sectionHash === FEASIBILITY_PLOT_TAB) {
            setMode(BUILDING_FEASIBILITY_MODE);
            return;
        }
        if (sectionHash === BUILDING_PLOT_TAB) {
            setMode(BUILDING_MODE);
        }
        else {
            setMode(null);
            setSelectedBuilding(null);
        }
    }, [sectionHash]);
    const focusOnBuilding = (item) => {
        setSectionHashToUrl(BUILDING_PLOT_TAB);
        setSelectedBuilding(item.buildingId);
        togglePlot({ resource: plots[item.plotId] });
        trackEvent({
            category: ANALYTICS_ONGLET,
            action: 'Bâtiment clic carte',
        });
    };
    const isClickable = useMemo(() => plotSelectionMode === PLOT_SELECTION_INITIAL && mode === BUILDING_MODE, [plotSelectionMode, mode, plots]);
    const providedData = useMemo(() => {
        return mode === BUILDING_FEASIBILITY_MODE && hasPotentialBuildings
            ? Object.values(potentialBuildings || {})
            : Object.values(buildings || {});
    }, [mode, hasPotentialBuildings, potentialBuildings, buildings]);
    createLayersHook({
        providedData,
        onClick: isClickable ? focusOnBuilding : undefined,
        updateOnChange: true,
        alwaysEnabled: providedData.length > 0,
        getCustomLayerInfo,
        legends,
    })(filtersKey.SELECTED_PLOTS_BUILDINGS);
};
const SelectedPlotsBuildingsLayer = () => {
    useSelectedPlotsBuildingsLayer();
    return null;
};
export default SelectedPlotsBuildingsLayer;
