import { startCase } from 'lodash';
import { isEmpty } from 'lodash';
import PlotMyCRM from 'components/tabs/plots/PlotSections/crm/PlotMyCRM';
import PlotFeasibility from 'components/tabs/plots/PlotSections/feasibility/PlotFeasibility';
import PlotInfo from 'components/tabs/plots/PlotSections/info/PlotInfo';
import { PlotNeighborhood } from 'components/tabs/plots/PlotSections/neighborhood';
import PlotOwner from 'components/tabs/plots/PlotSections/owner/PlotOwner';
import PlotBuilding from 'components/tabs/plots/PlotSections/PlotBuilding';
import PlotDPE from 'components/tabs/plots/PlotSections/PlotDPE';
import PlotOccupant from 'components/tabs/plots/PlotSections/PlotOccupant';
import PlotPluRegulation from 'components/tabs/plots/PlotSections/PlotPluRegulation';
import PlotSales from 'components/tabs/plots/PlotSections/PlotSales';
import PlotSourcePost from 'components/tabs/plots/PlotSections/PlotSourcePost';
import PlotRules from 'components/tabs/plots/PlotSections/rules/PlotRules';
import { BUILDING_PLOT_TAB, DPE_PLOT_TAB, FEASIBILITY_PLOT_TAB, MY_CRM_PLOT_TAB, NEIGHBORHOOD_PLOT_TAB, OCCUPANT_PLOT_TAB, OWNER_PLOT_TAB, PLOT_PLOT_TAB, PLOT_RULES_PLOT_TAB, PLU_REGULATION_PLOT_TAB, SALES_PLOT_TAB, SOURCE_POST_PLOT_TAB, } from 'types/api';
export const SECTIONS = {
    [PLOT_PLOT_TAB]: PlotInfo,
    [SOURCE_POST_PLOT_TAB]: PlotSourcePost,
    [FEASIBILITY_PLOT_TAB]: PlotFeasibility,
    //[FEASIBILITY_ADDITIONAL_PLOT_TAB]: PlotFeasibilityAdditional,
    [OWNER_PLOT_TAB]: PlotOwner,
    [PLU_REGULATION_PLOT_TAB]: PlotPluRegulation,
    //[PLU_ADDITIONAL_REGULATION_PLOT_TAB]: PlotPluAdditionalRegulation,
    [PLOT_RULES_PLOT_TAB]: PlotRules,
    [OCCUPANT_PLOT_TAB]: PlotOccupant,
    [BUILDING_PLOT_TAB]: PlotBuilding,
    [SALES_PLOT_TAB]: PlotSales,
    [MY_CRM_PLOT_TAB]: PlotMyCRM,
    [NEIGHBORHOOD_PLOT_TAB]: PlotNeighborhood,
    [DPE_PLOT_TAB]: PlotDPE,
};
export const TOOLBAR_HEIGHT = '32px';
// Get the last 4 digits of the plot id and remove leading zeros
export const getRefinedPlotNumber = (value) => {
    if (!value)
        return null;
    return Number(value.slice(10, 14));
};
export const getTownExportName = (townName) => {
    return startCase(townName).replaceAll(' ', '_');
};
export const getPlotExportName = (plot, townName) => {
    const prefix = plot.cadastreId.slice(5, 8);
    const section = plot.cadastreId.slice(8, 10);
    const number = plot.cadastreId.slice(10, 14);
    return `${getTownExportName(townName)}_${prefix}_${section}_${number}`;
};
export const getPlotCadastreId = (plot) => {
    const prefix = plot?.cadastreId.slice(5, 8) ?? '';
    const section = plot?.cadastreId.slice(8, 10) ?? '';
    const number = plot?.cadastreId.slice(10, 14) ?? '';
    return `${prefix} ${section} ${number}`;
};
export const getRiskLink = (plot) => {
    if (!isEmpty(plot?.address)) {
        return `https://www.georisques.gouv.fr/mes-risques/connaitre-les-risques-pres-de-chez-moi/rapport2?form-adresse=true&isCadastre=false&city=${plot?.town}&type=street&typeForm=adresse&codeInsee=${plot?.townId}&lon=${plot?.lng}&lat=${plot?.lat}&go_back=%2F&propertiesType=street&adresse=${plot?.address}`;
    }
    return 'https://www.georisques.gouv.fr/';
};
