import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { VECTOR_TYPES } from '../../types/types';
const IOElectricalNetworksBTUndergroundLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_bt_underground = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_BT_UNDERGROUND,
        label: 'electrical_networks_bt_underground',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#00FF00',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksBTUnderground = (townId) => () => get(iots.array(IOElectricalNetworksBTUndergroundLayerItem), `${townId}/electrical-networks/BT-underground`);
const useElectricalNetworksBTUndergroundLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksBTUnderground,
        legends: getLegends(),
    })(filtersKey.ELECTRICAL_NETWORKS_BT_UNDERGROUND);
};
const ElectricalNetworksBTUndergroundLayer = () => {
    useElectricalNetworksBTUndergroundLayer();
    return null;
};
export default ElectricalNetworksBTUndergroundLayer;
