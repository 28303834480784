import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
export const IOSolarRoofPotentialInfo = iots.type({
    system: iots.string,
    utilSurface: iots.number,
    production: iots.string,
    shape: iots.number,
    deposit: iots.string,
}, 'IOSolarRoofPotentialInfo');
const SolarRoofPotentialInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.deposit && (<Box>
          {t('solar_roof_potential.deposit')} : {info.deposit}
        </Box>)}
      {info.utilSurface && (<Box>
          {t('solar_roof_potential.util_surface')} : {info.utilSurface} m²
        </Box>)}
      {info.shape && (<Box>
          {t('solar_roof_potential.shape')} : {info.shape}
        </Box>)}
      {info.system && (<Box>
          {t('solar_roof_potential.system')} : {info.system}
        </Box>)}
      {info.production && (<Box>
          {t('solar_roof_potential.production')} : {info.production} MWh
        </Box>)}
    </Box>);
};
export default SolarRoofPotentialInfoWindow;
