import * as React from 'react';
import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import SolarRoofPotentialInfoWindow, { IOSolarRoofPotentialInfo, } from 'components/infoWindow/SolarRoofPotentialInfoWindow';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOSolarRoofPotentialLayerItem = IOLayerItem;
const fetchSolarRoofPotential = (townId) => () => get(iots.array(IOSolarRoofPotentialLayerItem), `${townId}/solar-roof-potential`);
const fetchSolarRoofPotentialInfo = (item) => {
    return get(IOSolarRoofPotentialInfo, `solar-roof-potential/${item.id}`);
};
const formatInfoWindowContent = (info) => {
    const content = <SolarRoofPotentialInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useSolarRoofPotentialLayer = () => {
    const [polygonInfo, setPolygonInfo] = useState({});
    const getInfoWindowContent = async (polygon) => {
        if (polygonInfo[polygon.id]) {
            return polygonInfo[polygon.id];
        }
        const SolarRoofPotentialInfo = await fetchSolarRoofPotentialInfo(polygon);
        const content = formatInfoWindowContent(SolarRoofPotentialInfo);
        setPolygonInfo({
            ...polygonInfo,
            [polygon.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchSolarRoofPotential,
        getInfoWindowContent,
    })(filtersKey.SOLAR_ROOF_POTENTIAL);
};
const SolarRoofPotentialLayer = () => {
    useSolarRoofPotentialLayer();
    return null;
};
export default SolarRoofPotentialLayer;
