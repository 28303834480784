import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { VECTOR_TYPES } from '../../types/types';
const IOElectricalNetworksBTAerialLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_bt_aerial = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL,
        label: 'electrical_networks_bt_aerial',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF0000',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksBTAerial = (townId) => () => get(iots.array(IOElectricalNetworksBTAerialLayerItem), `${townId}/electrical-networks/BT-aerial`);
const useElectricalNetworksBTAerialLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksBTAerial,
        legends: getLegends(),
    })(filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL);
};
const ElectricalNetworksBTAerialLayer = () => {
    useElectricalNetworksBTAerialLayer();
    return null;
};
export default ElectricalNetworksBTAerialLayer;
