import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { VECTOR_TYPES } from '../../types/types';
const IOElectricalNetworksHTAUndergroundLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_medium_hta_underground = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_UNDERGROUND,
        label: 'electrical_networks_medium_hta_underground',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF00FF',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksHTAUnderground = (townId) => () => get(iots.array(IOElectricalNetworksHTAUndergroundLayerItem), `${townId}/electrical-networks/HTA-underground`);
const useElectricalNetworksHTAUndergroundLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksHTAUnderground,
        legends: getLegends(),
    })(filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_UNDERGROUND);
};
const ElectricalNetworksHTAUndergroundLayer = () => {
    useElectricalNetworksHTAUndergroundLayer();
    return null;
};
export default ElectricalNetworksHTAUndergroundLayer;
