import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOSiteCarrousel } from 'types/api';
const QUERY_KEY = ['site-carrousel'];
const fetchSiteCarrousel = () => () => get(iots.array(IOSiteCarrousel), `site/carrousel`);
const useSiteCarrousel = () => {
    const { data: siteCarrousel, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchSiteCarrousel(),
    });
    return {
        siteCarrousel,
        isLoading,
    };
};
export default useSiteCarrousel;
