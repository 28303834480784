import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import LegendImage from 'images/legends/wind_potential_140.png';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrl(map, 'POTENTIEL.VENT.140M', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.wind_potential_140 = {
        layerKey: filtersKey.WIND_POTENTIAL_140,
        label: '',
        needsTranslate: false,
        image: LegendImage,
        height: '90px',
    };
    return legends;
};
const useWindPotential140Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_POTENTIAL_140],
        getLegends,
        opacity: 0.6,
    })(filtersKey.WIND_POTENTIAL_140);
};
const WindPotential140Layer = () => {
    useWindPotential140Layer();
    return null;
};
export default WindPotential140Layer;
