import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Checkbox, Spinner, Text, VStack } from '@chakra-ui/react';
import DPEInfoWindow from 'components/infoWindow/DPEInfoWindow';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import usePlotDPE from 'hooks/plot/usePlotDPE';
import { filtersKey } from 'utils/filters';
const PlotDPE = ({ plot }) => {
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const { dpe, isLoading } = usePlotDPE(plot?.id);
    const handleChange = (filterKey) => {
        toggleFilter(filterKey);
    };
    return (<VStack alignItems="flex-start" padding={4} color="var(--colorModeFont-800)">
      <Text fontWeight="bold">{t('tabs.plots.section.dpe.title_content')}</Text>
      <Checkbox key={filtersKey.DPE_OLD} data-id={filtersKey.DPE_OLD} isChecked={isFilterActive(filtersKey.DPE_OLD)} onChange={() => handleChange(filtersKey.DPE_OLD)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
        <Text fontSize="14px">{t('sidebar.categories.dpe_old')}</Text>
      </Checkbox>
      <Checkbox key={filtersKey.DPE_NEW} data-id={filtersKey.DPE_NEW} isChecked={isFilterActive(filtersKey.DPE_NEW)} onChange={() => handleChange(filtersKey.DPE_NEW)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
        <Text fontSize="14px">{t('sidebar.categories.dpe_new')}</Text>
      </Checkbox>
      <Checkbox key={filtersKey.DPE_TERTIARY} data-id={filtersKey.DPE_TERTIARY} isChecked={isFilterActive(filtersKey.DPE_TERTIARY)} onChange={() => handleChange(filtersKey.DPE_TERTIARY)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
        <Text fontSize="14px">{t('sidebar.categories.dpe_tertiary')}</Text>
      </Checkbox>
      <Box width="full" marginTop={2} fontSize="13px">
        {isLoading ? (<Center>
            <Spinner />
          </Center>) : dpe.length > 0 ? (<DPEInfoWindow info={Object.values(dpe)}/>) : (t('tabs.plots.section.dpe.no_result'))}
      </Box>
    </VStack>);
};
export default PlotDPE;
