import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, HStack, Switch, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import usePlotsSearchSavedFilters from 'hooks/plotsSearch/usePlotsSearchSavedFilters';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { plotsSearchFilterDetailToFormData, } from 'utils/plotsSearch';
import FavoriteOffIcon from '../../../images/icons/FavoriteOff';
import FavoriteOnIcon from '../../../images/icons/FavoriteOn';
const SavedPlotFilters = ({ appliedFilter, resetFilter, handleDelete, setAppliedFilter, hasSearch, isOpenSaveModal, onOpenSaveModal, }) => {
    const { t } = useTranslation();
    const { hasSearchField } = useSiteConfiguration();
    const canSaveFilters = hasSearchField('savedFilters');
    const { savedFilters } = usePlotsSearchSavedFilters();
    const { trackEvent } = useAnalytics();
    const { reset } = useFormContext();
    const toggleSavedFilter = (filter) => {
        if (appliedFilter && appliedFilter.id === filter.id) {
            resetFilter();
        }
        else {
            applySavedFilter(filter);
        }
    };
    const applySavedFilter = (filter) => {
        reset(plotsSearchFilterDetailToFormData(filter.filters, t));
        setAppliedFilter(filter);
    };
    return (<>
      {canSaveFilters && !!savedFilters && savedFilters.length > 0 && (<>
          <Accordion width="full" allowToggle marginTop={0}>
            <AccordionItem border={0}>
              <Heading boxShadow={accordionBoxShadow}>
                <AccordionButton width="full" alignItems="center" height="42px" data-cy="saved_filters_list" justifyContent="space-between">
                  <HStack width="full" position="relative" justifyContent="space-between">
                    <Text textStyle="paragraph" fontWeight={700} textAlign="left">
                      {t('sidebar.search_plots.saved_filters')}
                    </Text>
                    {/* ADD TO FAVORITES */}
                    {hasSearch &&
                canSaveFilters &&
                (isOpenSaveModal ? (<Box marginRight={2} height="auto" minWidth="auto">
                          <FavoriteOnIcon fill="red" aria-label="favorite-icon" onClick={(e) => {
                        e.preventDefault();
                        onOpenSaveModal();
                        trackEvent({
                            category: ANALYTICS_TELECOMMANDE_ACTIONS,
                            action: 'Sauvegarder le filtre',
                        });
                    }} data-cy="save_plot_search_filter"/>
                        </Box>) : (<Box marginRight={2} height="auto" minWidth="auto">
                          <FavoriteOffIcon fill="red" aria-label="favorite-icon" onClick={(e) => {
                        e.preventDefault();
                        onOpenSaveModal();
                        trackEvent({
                            category: ANALYTICS_TELECOMMANDE_ACTIONS,
                            action: 'Sauvegarder le filtre',
                        });
                    }} data-cy="save_plot_search_filter"/>
                        </Box>))}
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel padding={2} maxHeight={40} overflowY="scroll">
                {!!savedFilters &&
                savedFilters.map((filter) => (<HStack key={filter.id} flex={1} paddingLeft={2}>
                      <Box flex={1}>
                        <Text textStyle="paragraph">{filter.name}</Text>
                      </Box>
                      <Switch data-cy={filter.name.split(' ').join('_') + '_switch'} isChecked={!!appliedFilter && appliedFilter.id === filter.id} onChange={() => {
                        toggleSavedFilter(filter);
                        if (!!appliedFilter &&
                            appliedFilter.id === filter.id) {
                            trackEvent({
                                category: ANALYTICS_TELECOMMANDE_ACTIONS,
                                action: 'Filtre sauvegardé on',
                            });
                        }
                    }}/>
                      <DeleteButton id="plot_saved_filter_delete" data-cy={filter.name.split(' ').join('_') + '_delete'} mode="icon" confirmText={t('sidebar.search_plots.delete_filter_modal.content', {
                        filter: filter?.name,
                    })} handleDelete={() => {
                        handleDelete(filter?.id);
                        trackEvent({
                            category: ANALYTICS_TELECOMMANDE_ACTIONS,
                            action: 'Filtre sauvegardé supprimer',
                        });
                    }} size="sm"/>
                    </HStack>))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>)}
    </>);
};
export default SavedPlotFilters;
