import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from '@chakra-ui/react';
import { useFilters, useHiddenFilters } from 'hooks/contexts/useFiltersContext';
import { useAddLegend, useRemoveLegend } from 'hooks/contexts/useLegendContext';
import { useAddInfoWindow, useCloseInfoWindow, useMap, } from 'hooks/contexts/useMapContext';
const TILE_SIZE = 256; // ne pas changer car https://data.geopf.fr/wmts est calculer avec 256 (ou sinon avoir un moyen de calculer  avec le tileSize)
const showLayer = (map, getLayerTileUrl, position, enabledFilters, opacity, tileWidth = TILE_SIZE, tileHeight = TILE_SIZE) => {
    const SLPLayer = new google.maps.ImageMapType({
        getTileUrl: getLayerTileUrl(map, tileWidth, tileHeight, enabledFilters),
        tileSize: new google.maps.Size(tileWidth, tileHeight),
        opacity,
    });
    map.overlayMapTypes.setAt(position, SLPLayer);
};
export const hideLayer = (map, position) => {
    if (map) {
        map.overlayMapTypes.setAt(position, null);
    }
};
export const toggleLayer = (enabledFilters, map, getLayerTileUrl, position, opacity, tileWidth, tileHeight) => {
    if (enabledFilters.length > 0) {
        showLayer(map, getLayerTileUrl, position, enabledFilters, opacity ?? 1, tileWidth, tileHeight);
        return;
    }
    hideLayer(map, position);
};
const refreshLayer = (enabledFilters, map, getLayerTileUrl, position, opacity, tileWidth, tileHeight) => {
    hideLayer(map, position);
    if (enabledFilters.length > 0) {
        showLayer(map, getLayerTileUrl, position, enabledFilters, opacity, tileWidth, tileHeight);
        return;
    }
};
export default ({ getLayerTileUrl, position, getLegends = () => ({}), refreshParam, opacity = 1, getInfoWindowContent, tileWidth, tileHeight, }) => async (filterKeys) => {
    filterKeys = typeof filterKeys === 'string' ? [filterKeys] : filterKeys;
    const map = useMap();
    const addLegend = useAddLegend();
    const removeLegend = useRemoveLegend();
    const [activeLegends, setActiveLegends] = useState({});
    const filters = useFilters();
    const hiddenFilters = useHiddenFilters();
    const previousRefreshParam = usePrevious(refreshParam);
    const addInfoWindow = useAddInfoWindow();
    const closeInfoWindow = useCloseInfoWindow();
    const enabledFilters = useMemo(() => filterKeys.filter((filterName) => !!filters[filterName]), [filters, JSON.stringify(filterKeys)]);
    const toggleClickListener = useCallback((enabledFilters) => {
        if (getInfoWindowContent && enabledFilters.length > 0) {
            addInfoWindow(map, getInfoWindowContent(tileWidth || TILE_SIZE, tileHeight || TILE_SIZE), enabledFilters[0]);
        }
    }, [map, getInfoWindowContent]);
    const toggleLegends = useCallback(async (enabledFilters) => {
        const newLegends = await getLegends(enabledFilters);
        Object.keys(activeLegends).forEach((id) => {
            if (!newLegends[id]) {
                removeLegend(activeLegends[id].layerKey, id);
            }
        });
        Object.keys(newLegends).forEach((id) => {
            addLegend(id, newLegends[id]);
        });
        setActiveLegends(newLegends);
    }, [JSON.stringify(filterKeys), activeLegends, getLegends]);
    const refreshLegends = useCallback(async (enabledFilters) => {
        await toggleLegends([]);
        toggleLegends(enabledFilters);
    }, [toggleLegends]);
    const enabledLayers = useMemo(() => {
        return enabledFilters.filter((filter) => {
            const radioName = filters[filter];
            // only for thematic maps check if it's a string value
            if (typeof radioName === 'string')
                return !hiddenFilters.includes(radioName.toString());
            return !hiddenFilters.includes(filter);
        });
    }, [JSON.stringify(enabledFilters), JSON.stringify(hiddenFilters)]);
    useEffect(() => {
        if (!map) {
            return;
        }
        toggleLayer(enabledLayers, map, getLayerTileUrl, position, opacity, tileWidth, tileHeight);
        toggleClickListener(enabledFilters);
    }, [JSON.stringify(enabledLayers), map]);
    useEffect(() => {
        toggleLegends(enabledFilters);
    }, [enabledFilters]);
    useEffect(() => {
        if (map && refreshParam !== previousRefreshParam) {
            refreshLayer(enabledFilters, map, getLayerTileUrl, position, opacity, tileWidth, tileHeight);
            refreshLegends(enabledFilters);
        }
    }, [refreshParam]);
    const cleanup = () => {
        hideLayer(map, position);
        closeInfoWindow();
        const filters = filterKeys;
        filters.forEach((filter) => {
            removeLegend(filter);
        });
    };
    useEffect(() => {
        return cleanup;
    }, []);
};
