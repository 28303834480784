import * as React from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Icon, Text, useDisclosure, usePrevious, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import AltimetryModal from 'components/modals/AltimetryModal';
import { useDrawingMode, useSetDrawingMode, } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import useGetAltimetry from 'hooks/useAltimetry';
import MesureIcon from 'images/icons/Measure';
import MountainIcon from 'images/icons/Mountain';
import SurfaceIcon from 'images/icons/Surface';
import { numberWithSpaces } from 'utils/text';
const DRAW_COLOR = '#00FA9A';
const DRAW_OPTIONS = {
    strokeColor: DRAW_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillOpacity: 0.3,
    fillColor: DRAW_COLOR,
    editable: true,
    draggable: false,
    zIndex: 1,
};
const DREW_COLOR = '#00d2ff';
const pointExists = (path, point) => {
    for (let i = 0; i < path.length; i++) {
        const p = path[i];
        if (p.lat() === point.lat() && p.lng() === point.lng()) {
            return true;
        }
    }
    return false;
};
const getDistanceResultContent = (points, t) => {
    let distance = google.maps.geometry.spherical.computeLength(points);
    distance = Math.round(distance * 100) / 100;
    const distanceKm = distance / 1000;
    const distanceResult = {
        meter: numberWithSpaces(distance.toFixed(2)),
        km: distanceKm.toFixed(2),
    };
    return `<div>${t('toolbar.measure.distance_result', distanceResult)}</div>`;
};
const MeasureTool = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const map = useMap();
    const { trackEvent } = useAnalytics();
    const activeDrawing = useRef(null);
    const validatedPoints = useRef([]);
    const clickListener = useRef(null);
    const mouseMoveListener = useRef(null);
    const drawingsData = useRef([]);
    const drawingMode = useDrawingMode();
    const setDrawingMode = useSetDrawingMode();
    const [altimetryPoints, setAltimetryPoints] = useState(null);
    const { isLoading, data: altimetry } = useGetAltimetry(altimetryPoints);
    const previousAltimetry = usePrevious(altimetry);
    useEffect(() => {
        return () => {
            cleanAll();
            setDrawingMode(null);
        };
    }, []);
    useEffect(() => {
        if (!previousAltimetry && isLoading) {
            onOpen();
        }
        if (!altimetry && !isLoading) {
            onClose();
        }
    }, [isLoading]);
    const displayResults = useCallback((drawing, content, position) => {
        const drawingData = drawingsData.current.find((d) => d.drawing === drawing);
        if (!drawingData) {
            return;
        }
        let infoWindow = drawingData.infoWindow;
        if (infoWindow) {
            infoWindow.setContent(content);
            infoWindow.setPosition(position);
            return;
        }
        infoWindow = new google.maps.InfoWindow({
            content: content,
            position: position,
            pixelOffset: new google.maps.Size(0, -8),
            maxWidth: 700,
        });
        drawingData.infoWindow = infoWindow;
        infoWindow.addListener('close', () => {
            removeDrawing(drawing);
        });
        infoWindow.open(map);
    }, [map]);
    const showLiveResult = (mode, position) => {
        const currentPath = activeDrawing.current.getPath();
        if (currentPath.getLength() > validatedPoints.current.length) {
            currentPath.pop();
        }
        addPoint(position, activeDrawing.current);
        if (mode === 'altimetry') {
            return;
        }
        showResult(mode, activeDrawing.current);
    };
    const showResult = (mode, drawing) => {
        const path = drawing.getPath();
        let position = null;
        let content = null;
        if (mode === 'area') {
            if (path.getLength() < 3) {
                return;
            }
            let area = google.maps.geometry.spherical.computeArea(path);
            area = Math.round(area * 100) / 100;
            const areaKm = area / 1000000;
            const areaHa = area / 10000;
            const bounds = new google.maps.LatLngBounds();
            path.forEach(function (latlng) {
                bounds.extend(latlng);
            });
            const northEastBounds = bounds.getNorthEast();
            const southWestBounds = bounds.getSouthWest();
            const lngMax = northEastBounds.lng();
            const lngMin = southWestBounds.lng();
            const latMax = northEastBounds.lat();
            const lng = lngMin + (lngMax - lngMin) / 2;
            position = new google.maps.LatLng(latMax, lng);
            content = `<div>${t('toolbar.measure.area_result', {
                meter: numberWithSpaces(area.toFixed(2)),
                ha: areaHa.toFixed(2),
                km: areaKm.toFixed(2),
            })}</div>`;
        }
        else {
            const points = path.getArray();
            if (mode == 'distance') {
                position = points[points.length - 1];
                content = getDistanceResultContent(points, t);
            }
            if (mode == 'altimetry' && !activeDrawing.current) {
                setAltimetryPoints(points.map((point) => ({
                    lat: point.lat().toString(),
                    lng: point.lng().toString(),
                })));
            }
        }
        if (content && position) {
            displayResults(drawing, content, position);
        }
    };
    const createDrawing = (latLng, mode) => {
        const startingPoint = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };
        let newDrawing = null;
        validatedPoints.current = [latLng];
        if (mode === 'area') {
            newDrawing = new google.maps.Polygon({
                paths: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        else {
            newDrawing = new google.maps.Polyline({
                path: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        activeDrawing.current = newDrawing;
        newDrawing.setMap(map);
        const listener = newDrawing.addListener('click', (event) => {
            // new point clicked
            if (!pointExists(validatedPoints.current, event.latLng)) {
                validatedPoints.current.push(event.latLng);
                addPoint(event.latLng, newDrawing);
                return;
            }
            // drawing completed
            showResult(mode, newDrawing);
            stopCurrentDrawing(mode);
        });
        drawingsData.current.push({
            drawing: newDrawing,
            infoWindow: null,
            listener,
        });
        // Recompute the result when the drawing is modified
        newDrawing.addListener('bounds_changed', () => {
            showResult(mode, newDrawing);
        });
        const path = newDrawing.getPath();
        path.addListener('set_at', () => {
            showResult(mode, newDrawing);
        });
        path.addListener('insert_at', () => {
            showResult(mode, newDrawing);
        });
        path.addListener('remove_at', () => {
            showResult(mode, newDrawing);
        });
    };
    const addPoint = (latLng, drawing) => {
        const path = drawing.getPath();
        path.push(latLng);
    };
    const startDrawing = (mode) => {
        if (!map) {
            return;
        }
        map.getDiv().classList.add('crosshair-cursor');
        setDrawingMode(mode);
        clickListener.current = map.addListener('click', (event) => {
            const latLng = event.latLng;
            // drawing started
            if (!activeDrawing?.current) {
                createDrawing(latLng, mode);
                return;
            }
            // new point added
            if (!pointExists(validatedPoints.current, latLng)) {
                validatedPoints.current.push(latLng);
                addPoint(latLng, activeDrawing.current);
                return;
            }
            // drawing completed
            showResult(mode, activeDrawing.current);
            stopCurrentDrawing(mode);
        });
        mouseMoveListener.current = map.addListener('mousemove', (event) => {
            if (!activeDrawing?.current) {
                return;
            }
            showLiveResult(mode, event.latLng);
        });
    };
    const removeDrawing = (drawing) => {
        if (!drawing) {
            return;
        }
        drawing.setMap(null);
        const index = drawingsData.current.findIndex((d) => d.drawing === drawing);
        drawingsData.current[index]?.infoWindow?.close();
        drawingsData.current.splice(index, 1);
    };
    const stopCurrentDrawing = (mode) => {
        const drawingData = drawingsData.current.find((d) => d.drawing === activeDrawing.current);
        drawingData.drawing.setOptions({
            strokeColor: DREW_COLOR,
            fillColor: DREW_COLOR,
        });
        drawingData.listener?.remove();
        drawingData.drawing.addListener('click', () => {
            removeDrawing(drawingData.drawing);
        });
        if (mode === 'altimetry') {
            setAltimetryPoints(drawingData.drawing
                .getPath()
                .getArray()
                .map((point) => ({
                lat: point.lat().toString(),
                lng: point.lng().toString(),
            })));
        }
        activeDrawing.current = null;
    };
    const stopDrawing = useCallback(() => {
        if (!map) {
            return;
        }
        map.getDiv().classList.remove('crosshair-cursor');
        if (clickListener.current) {
            google.maps.event.removeListener(clickListener.current);
            clickListener.current = null;
        }
        if (mouseMoveListener.current) {
            google.maps.event.removeListener(mouseMoveListener.current);
            mouseMoveListener.current = null;
        }
    }, [map]);
    const toggleDrawingMode = (mode) => {
        if (drawingMode) {
            cleanAll();
        }
        if (drawingMode === mode) {
            setDrawingMode(null);
            return;
        }
        startDrawing(mode);
    };
    const cleanAll = () => {
        setAltimetryPoints(null);
        stopDrawing();
        const drawingsDataTemp = [...drawingsData.current];
        drawingsDataTemp.forEach(({ drawing, infoWindow }) => {
            drawing.setMap(null);
            infoWindow?.close();
        });
        drawingsData.current = [];
        validatedPoints.current = [];
        activeDrawing.current = null;
        onClose();
    };
    return (<VStack spacing={2} alignItems="flex-end">
      <Button variant="ghost" size="sm" boxShadow="lg" isActive={drawingMode === 'distance'} onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Mesurer une distance',
            });
            toggleDrawingMode('distance');
        }}>
        <HStack justifyContent="center" alignItems="center">
          <Icon as={MesureIcon} width={5} height={5} fill="currentColor"/>
          <Text>{t('toolbar.measure.distance')}</Text>
        </HStack>
      </Button>
      <Button variant="ghost" size="sm" boxShadow="lg" isActive={drawingMode === 'area'} onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Mesurer une surface',
            });
            toggleDrawingMode('area');
        }}>
        <HStack justifyContent="center" alignItems="center">
          <Icon as={SurfaceIcon} width={4} height={3} fill="currentColor"/>
          <Text>{t('toolbar.measure.area')}</Text>
        </HStack>
      </Button>
      <Button variant="ghost" size="sm" boxShadow="lg" isActive={drawingMode === 'altimetry'} onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Mesurer l’altimétrie',
            });
            toggleDrawingMode('altimetry');
        }}>
        <HStack justifyContent="center" alignItems="center">
          <Icon as={MountainIcon} width={5} height={5} fill="currentColor"/>
          <Text>{t('toolbar.measure.altimetry')}</Text>
        </HStack>
      </Button>

      {isOpen && (<AltimetryModal isLoading={isLoading} altimetry={altimetry} handleOnClose={() => {
                cleanAll();
                setDrawingMode(null);
            }}/>)}
    </VStack>);
};
export default memo(MeasureTool);
