import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as Navigation } from 'react-router-dom';
import { Box, Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Image, Input, InputGroup, InputRightElement, Link, Spinner, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_LOGIN } from 'analytics/useAnalytics';
import { FORGOTTEN_PASSWORD_URL } from 'app';
import { isRight } from 'fp-ts/lib/These';
import LanguageDropdown from 'components/dropdowns/LanguageDropdown';
import TwoFactorForm from 'components/security/TwoFactorForm';
import { IOAuthTwoFactorInProgress, } from 'context/AuthContext';
import { useLogin } from 'hooks/contexts/useAuthContext';
import useSite, { getSiteLogo, getSiteName } from 'hooks/useSite';
import PwdIcon from 'images/icons/Pwd';
import PwdHideIcon from 'images/icons/Pwd-hide';
const LoginForm = ({ redirect }) => {
    const { t } = useTranslation();
    const { login, loginError } = useLogin();
    const [showPassword, setShowPassword] = useState(false);
    const { trackEvent } = useAnalytics();
    const { site, isLoading } = useSite();
    const { register, handleSubmit, formState: { isSubmitting }, } = useForm({
        defaultValues: { email: null, password: null, keepAlive: true },
    });
    const [twoFactorInProgress, setTwoFactorInProgress] = useState(null);
    const handleClick = () => {
        if (!showPassword) {
            trackEvent({
                category: ANALYTICS_LOGIN,
                action: 'Mot de passe visible',
            });
        }
        setShowPassword(!showPassword);
    };
    const onLogin = async (values) => {
        const result = await login(values, redirect);
        if (isRight(IOAuthTwoFactorInProgress.decode(result))) {
            setTwoFactorInProgress(result);
        }
    };
    return (<VStack height="100vh" bgColor="colorMode.background900">
      <Box position="absolute" right={5} top={5}>
        <LanguageDropdown />
      </Box>

      <VStack flex={1} justifyContent="center">
        {isLoading ? (<Spinner />) : (<Image src={getSiteLogo(site)} objectFit="contain" w="150px" marginBottom="64px"/>)}

        {twoFactorInProgress ? (<TwoFactorForm twoFactorInProgress={twoFactorInProgress} setTwoFactorInProgress={setTwoFactorInProgress} redirect={redirect}/>) : (<form onSubmit={handleSubmit(onLogin)} id="login-form">
            <Stack paddingBottom={4} width="300px" spacing={1}>
              <Heading as="p" variant="h5" color="colorMode.font800">
                {t('login.email')}
              </Heading>
              <FormControl isInvalid={!!loginError}>
                <Input {...register('email')} autoComplete="username"/>
                {loginError !== null && (<FormErrorMessage>
                    {t(`login.error.${loginError}`)}
                  </FormErrorMessage>)}
              </FormControl>
            </Stack>

            <Stack width="300px" spacing={1}>
              <Heading as="p" variant="h5" fontWeight={400} color="colorMode.font800">
                {t('login.password')}
              </Heading>
              <FormControl isInvalid={!!loginError}>
                <InputGroup>
                  <Input autoComplete="current-password" placeholder={t('login.password')} type={showPassword ? 'text' : 'password'} {...register('password')}/>
                  <InputRightElement>
                    <IconButton size="sm" cursor="pointer" bgColor="transparent" color="colorMode.font800" icon={showPassword ? (<PwdIcon fill="currentColor"/>) : (<PwdHideIcon fill="currentColor"/>)} aria-label={'input-icon'} onClick={handleClick}/>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>

            <Box width="full" paddingBottom={4}>
              <Navigation to={FORGOTTEN_PASSWORD_URL} onClick={() => trackEvent({
                category: ANALYTICS_LOGIN,
                action: 'Mot de passe oublié',
            })}>
                <Text textStyle="small" textAlign="right" cursor="pointer" data-cy="forgotten_password_link">
                  {t('login.forgotten_password')}
                </Text>
              </Navigation>
            </Box>

            <Box width="full" paddingBottom={4}>
              <FormControl display="flex">
                <Checkbox {...register('keepAlive')} onClick={() => trackEvent({
                category: ANALYTICS_LOGIN,
                action: 'Rester connecté',
            })}/>
                <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                  {t('login.keep_alive')}
                </FormLabel>
              </FormControl>
            </Box>

            <Button disabled={isSubmitting} variant="primary" width="full" type="submit" onClick={() => trackEvent({
                category: ANALYTICS_LOGIN,
                action: 'Se connecter',
            })}>
              {t('login.signin')}
            </Button>
          </form>)}
      </VStack>

      <Box width="full" paddingBottom="56px">
        <Text textStyle="paragraph" textAlign="center">
          {t('login.no_account_info', { name: getSiteName(site) })}
          <Link href="https://kelfoncier.com/test-gratuit" isExternal variant="primary" paddingLeft={2} onClick={() => trackEvent({
            category: ANALYTICS_LOGIN,
            action: 'Inscrivez-vous',
        })}>
            {t('login.signup')}
          </Link>
        </Text>
      </Box>
    </VStack>);
};
export default LoginForm;
