import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
const IOElectricalNetworksHTBUndergroundLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_htb_zero = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_zero',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#888888',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_45 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_45',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#000000',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_63 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_63',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#BF0054',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_90 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_90',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF8000',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_150 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_150',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#003DB0',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_225 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_225',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#009414',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_400 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_400',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF0000',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksHTBUnderground = () => () => get(iots.array(IOElectricalNetworksHTBUndergroundLayerItem), `electrical-networks/HTB-underground`);
const useElectricalNetworksHTBUndergroundLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksHTBUnderground,
        legends: getLegends(),
    })(filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND);
};
const ElectricalNetworksHTBUndergroundLayer = () => {
    useElectricalNetworksHTBUndergroundLayer();
    return null;
};
export default ElectricalNetworksHTBUndergroundLayer;
