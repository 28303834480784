import * as React from 'react';
import { useMemo, useState } from 'react';
import TeamProjectModal from 'components/modals/TeamProjectModal';
import { tableFiltersKey } from 'context/FiltersContext';
import { useTableFilters } from 'hooks/contexts/useFiltersContext';
import { useLocatedProject } from 'hooks/contexts/useLocationContext';
import useTeamProjects from 'hooks/crm/useTeamProjects';
import createLayersHook from 'hooks/layers/createLayersHook';
import { VECTOR_TYPES } from 'types/types';
import { getProjectStatusFromCode, PROJECT_STATUS_VALUES, PROJECT_STATUS_VALUES_WITH_NONE, } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const legends = {};
const useTeamProjectsLayer = ({ setProject, setIsOpen, }) => {
    const locatedProject = useLocatedProject();
    const { teamProjects } = useTeamProjects();
    const tableFilters = useTableFilters();
    const userEmailFilter = tableFilters[tableFiltersKey.PLOT_USER_EMAIL];
    const projectLayerItems = useMemo(() => {
        if (!teamProjects) {
            return [];
        }
        const items = [];
        teamProjects?.forEach((teamProject) => {
            if (userEmailFilter && !userEmailFilter.includes(teamProject.userEmail)) {
                return;
            }
            teamProject.plots.forEach((plot) => {
                items.push({
                    id: plot.id.toString(),
                    layerKey: filtersKey.TEAM_PROJECTS,
                    swkt: plot.swkt,
                    color: plot.color,
                    userEmail: teamProject.userEmail,
                    projectStatus: teamProject.status,
                    projectId: teamProject.id,
                    townId: teamProject.townId,
                });
            });
            legends[PROJECT_STATUS_VALUES_WITH_NONE[teamProject.status].legendOrder] =
                {
                    ...PROJECT_STATUS_VALUES[teamProject.status],
                    borderWidth: 2,
                    borderColor: '#000',
                    layerKey: filtersKey.TEAM_PROJECTS,
                    label: getProjectStatusFromCode(teamProject.status),
                };
        });
        return items;
    }, [teamProjects, userEmailFilter]);
    createLayersHook({
        providedData: projectLayerItems,
        legends: legends,
        onClick: (item) => {
            setProject(item);
            setIsOpen(true);
        },
    })(filtersKey.TEAM_PROJECTS);
    const projectPlotsLayerItems = useMemo(() => {
        if (!teamProjects || !locatedProject) {
            return [];
        }
        const items = {};
        teamProjects.forEach((project) => {
            if (project.id === locatedProject) {
                project?.plots?.forEach((plot) => {
                    items[plot.id] = {
                        id: `${project.id}-${plot.id}`,
                        layerKey: filtersKey.TEAM_PROJECTS,
                        type: VECTOR_TYPES.polygon,
                        swkt: plot.swkt,
                        opacity: 0.7,
                        color: plot.color,
                    };
                });
            }
        });
        return Object.values(items);
    }, [teamProjects, locatedProject]);
    createLayersHook({
        providedData: projectPlotsLayerItems,
    })(filtersKey.TEAM_PROJECTS);
};
const TeamProjectsLayer = () => {
    const [project, setProject] = useState();
    const [isOpen, setIsOpen] = useState(false);
    useTeamProjectsLayer({ setProject, setIsOpen });
    return (<TeamProjectModal project={{
            projectTownId: project?.townId,
            projectId: project?.projectId,
            label: project?.label,
            lat: project?.lat,
            lng: project?.lng,
            userEmail: project?.userEmail,
        }} isOpen={isOpen} onClose={() => setIsOpen(false)}/>);
};
export default TeamProjectsLayer;
