import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Text, useDisclosure, } from '@chakra-ui/react';
const ConfirmButton = ({ children, confirmText, handleConfirm, confirmLabel, id, }) => {
    const { t } = useTranslation();
    const { onOpen, onClose, isOpen } = useDisclosure();
    return (<Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
      <Box display="flex">
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent width="auto">
          <PopoverCloseButton top={0} right={0}/>
          <PopoverBody display={'flex'} flexDir={'column'} alignItems={'center'} maxW="200px" width="auto">
            <>
              {confirmText && (<Text fontSize="sm" paddingBottom={2} paddingRight={1} textAlign="center" dangerouslySetInnerHTML={{
                __html: confirmText,
            }}></Text>)}
              <HStack>
                {handleConfirm && (<Button variant="primary" onClick={() => {
                handleConfirm();
                onClose();
            }} data-cy={id + '_confirm_button'}>
                    {confirmLabel ?? t('global.actions.confirm')}
                  </Button>)}
              </HStack>
            </>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>);
};
export default ConfirmButton;
